import Keyword from "./Keyword";

// const TranscriptText = ({ text, keywords }) => {
//     const words = text.split(' ');
//     const keywords1 = keywords.length && keywords.map((e)=>e.source_language);
//     console.log(keywords1);
//     return (
//       <p>
//         {words.map((word, index) => {
//           if (keywords1.includes(word)) {
//             return <Keyword key={index} word={word} />;
//           }
//           return <span key={index} style={{ marginRight: '4px' }}>{word} </span>;
//         })}
//       </p>
//     );
//   };

// Main component to parse sentence and match keywords
const TranscriptTextEditHeighlight = ({ text, keywords, callbackForAudio }) => {
  console.log("text", text);
  const splitSentenceWithKeywords = (sentence, keywords) => {
    let splitSentence = sentence;

    // Replace each occurrence of source_language with a special marker
    keywords.forEach((keyword) => {
      const escapedKeyword = escapeRegex(keyword.source_language);
      const regex = new RegExp(`(${escapedKeyword})`, "u"); // 'g' for global, 'u' for Unicode

      // Use a unique marker to split the string
      splitSentence = splitSentence.replace(
        regex,
        `<span style="color: blue;">${keyword.source_language}</span>`
      );
    });

    // Split the string by the marker
    // const splitArray = splitSentence.split("[SPLIT_HERE]").filter(Boolean);

    return splitSentence;
  };

  // Utility function to escape special characters in keywords for regex
  const escapeRegex = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  };
  //   console.log("output", splitSentenceWithKeywords(text, keywords));

  const processedText = splitSentenceWithKeywords(text, keywords);

  return (
    <div
      className="text scrollbar"
      dangerouslySetInnerHTML={{ __html: processedText }}
    ></div>
  );
};
export default TranscriptTextEditHeighlight;
