import React from "react";
import "./EditModal.css"; // External CSS file for styling
import images from "../../../images";

const EditModal = ({ onConfirm, onCancel }) => {
  return (
    <div className="edit-modal-overlay">
      <div className="edit-modal">
        <div className="edit-modal-header">
          <span className="edit-modal-icon">
            <img src={images.checkEdit} />
          </span>
        </div>
        <div className="edit-modal-body">
          <h2 className="edit-modal-title">Are you sure?</h2>
          <p className="edit-modal-description">
            {/* Do you want to edit this practice? It will create a copy in your
            Published Practice section, where you can edit the copied practice. */}
            Are you sure you want to edit this practice? It will be treated as a
            new practice and a copy will be created in your Published Practices
            section, where you can modify settings like words per minute, voice
            style, vocabulary, and more.
          </p>
        </div>
        <div className="edit-modal-footer">
          <button
            className="edit-modal-btn edit-modal-btn-no"
            onClick={onCancel}
          >
            NO
          </button>
          <button
            className="edit-modal-btn edit-modal-btn-yes"
            onClick={onConfirm}
          >
            YES
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
