import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import images from "../../images";
import "./HeaderWhite.css";
import { useNavigate } from "react-router";
import { logout } from "../../services/user.service";
import { UserContext } from "../../providers/UserContext";

function HeaderWhiteComponent() {
  const { currentUser } = useContext(UserContext);
  const [firstName, setFirstName] = useState("");
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const logoClick = () => {
    navigate("/welcome");
  };
  const DashboardClick = () => {
    navigate("/practice-dashboard");
  };
  // const handleScroll = () => {
  //   if (window.pageYOffset > 0) {
  //     setIsSticky(true);
  //   } else {
  //     setIsSticky(false);
  //   }
  // };

  useEffect(() => {
    const storedFirstName = currentUser.first_name;
    if (storedFirstName) {
      const name =
        storedFirstName.charAt(0).toUpperCase() +
        storedFirstName.slice(1).toLowerCase();
      setFirstName(name);
    }
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const logOutCliked = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const accessToken = localStorage.getItem("accessToken");

      if (!refreshToken) {
        navigate("/login");
        return;
      }

      const data = await logout(accessToken, refreshToken);

      if (data.status) {
        throw new Error(`HTTP error! Status: ${data.message}`);
      }

      localStorage.clear();

      navigate("/login");
    } catch (error) {
      localStorage.clear();
      navigate("/login");
      console.error("Logout error:", error);
    }
  };
  const ProfileCliked = () => {
    navigate("/update-profile");
  };
  return (
    <Navbar
      bg="transparent"
      expand="lg"
      className={`custom-navbar-white ${isSticky ? "sticky" : ""}`}
    >
      <Navbar.Brand href="#">
        <img
          src={images.logo}
          alt="logo"
          className="logo-img"
          onClick={logoClick}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarSupportedContent" />
      <Navbar.Collapse id="navbarSupportedContent">
        <Nav className="ml-auto nav-links p-2">
          <Nav.Link
            className="mb-1"
            style={{ color: "white" }}
            onClick={DashboardClick}
          >
            Dashboard
          </Nav.Link>
          {/* <Nav.Link className="mb-1" style={{ color: "white" }} href="#">
            <img src={images.userAvtarBlack} alt="user" className="user-icon" />
          </Nav.Link> */}
          <NavDropdown title={firstName} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={ProfileCliked}>
              {" "}
              Profile{" "}
            </NavDropdown.Item>
            <NavDropdown.Item onClick={logOutCliked}>Log Out</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default HeaderWhiteComponent;
