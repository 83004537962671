import React, { useContext, useEffect, useState } from "react";
import HeaderComponent from "../../components/Header/Header";
import { useNavigate, useParams } from "react-router";
import { fetchRecordingsByIdentifier } from "../../services/audio.service";
import { UserContext } from "../../providers/UserContext";
import { toast } from "react-toastify";
import { REACT_APP_GET_TRANSCRIPT_API } from "../../constants";

const Recording = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const viewRecording = (recordindId) => {
    navigate(`/view-recording-with-keyword/${id}/${recordindId}`);
  };
  const shareRecording = (recordingId) => {
    const shareableUrl = `${window.location.origin}/view-recording-with-keyword/${id}/${recordingId}`;
    navigator.clipboard.writeText(shareableUrl).then(() => {
      toast.success("Share link successfully copied");
    });
    console.log(shareableUrl, "shareableUrl");
  };
  const { currentUser } = useContext(UserContext);
  const [recordingsList, setRecordingsList] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    // const token = localStorage.getItem("accessToken");

    const fetchTranscriptText = async () => {
      const response = await fetchRecordingsByIdentifier(
        id,
        currentUser.access
      );

      if (response.success) {
        setRecordingsList(response.data);
      }
    };

    if (id && currentUser && currentUser.access) {
      fetchTranscriptText();
    }
  }, [id, currentUser]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  const filteredRecordings = recordingsList.filter(
    (item) =>
      item.recording_name.toLowerCase().includes(search.toLowerCase()) ||
      item.username.toLowerCase().includes(search.toLowerCase())
  );
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const goToBack = () => {
    navigate("/practice-dashboard");
  };

  return (
    <>
      <div className="min-screen recordingContent">
        <HeaderComponent />
        <div className="mainContentBox">
          <div className="main-content">
            <h1 className="title">RECORDING(S)</h1>
            <div className="card">
              <div className="card-body">
                {/* <ul className="record-detail">
                  <li>
                    <h5 className="yellow">Words per minute</h5>
                    <p>100</p>
                  </li>
                  <li>
                    <h5 className="purple">Level</h5>
                    <p>Intermediate</p>
                  </li>
                  <li>
                    <h5>Topic</h5>
                    <p>Workers compensation</p>
                  </li>
                  <li>
                    <h5>Created at</h5>
                    <p>11/12/2024</p> */}
                {/* </li>
                </ul> */}

                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text inputGroupradius ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        fill="rgba(136,136,136,1)"
                      >
                        <path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"></path>
                      </svg>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={search}
                    onChange={handleSearchChange}
                  />
                </div>
                {/* <div className="title-recording-div">
                  <span className="title-table-view">
                    Admin View recordings
                  </span>
                </div> */}
                <div className="table-responsive">
                  <table className="table table-custom ">
                    <thead>
                      <tr>
                        <th style={{ color: "#E19133" }}>Recording Name</th>
                        <th style={{ color: "#845EBC" }}>Created On</th>
                        <th style={{ color: "#F36960" }}>Username</th>
                        <th style={{ color: "#F36960" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredRecordings.length > 0 ? (
                        filteredRecordings.map((item) => (
                          <tr key={item.id}>
                            <td className="recording-td">
                              {item.recording_name}
                            </td>
                            <td className="recording-td">
                              {formatDate(item.created_at)}
                            </td>
                            <td className="recording-td">{item.username}</td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                borderStyle: "none",
                              }}
                            >
                              <button
                                className="lightVocab"
                                onClick={() => viewRecording(item.id)}
                                style={{ marginRight: "15px" }}
                              >
                                View recording
                              </button>
                              <button
                                className="lightVocab"
                                onClick={() => shareRecording(item.id)}
                              >
                                Share recording
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" style={{ textAlign: "center" }}>
                            No data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="btn-list">
                  <button className="small" onClick={goToBack}>
                    back
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="Copyright">
            <p>Copyright © Interpretrain, All rights reserved 2024.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Recording;
