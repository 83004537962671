import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderComponent from "components/Header/Header";
import Button from "components/Button/SignupButton";
import { Link } from "react-router-dom";
import ConfirmDeleteModal from "../../../components/Modal/DeleteModal/VocabDeletModal";
import {
  setVocabList,
  regenerateKeyword,
  navigateKeywordHistory,
} from "../../../redux/vocabSlice";
import { useSelector, useDispatch } from "react-redux";
import { FormContext } from "../../../providers/FormContext";
import Loader from "../../../components/Loader/Loader";
import Footer from "../../../components/Footer/Footer";
import { toast } from "react-toastify";
import { UserContext } from "../../../providers/UserContext";
import {
  generateVocabulary,
  saveVocabPractice,
} from "../../../services/vocab.service";
import { REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE } from "../../../constants";
import TestNameModal from "../../../components/Modal/Practise_test_modal/TestNameModal";
import VocabListModal from "../../../components/VocablaryList/VocablaryList";
import images from "../../../images";
import RegenerateTranscript from "../../../components/Modal/RegenerateTranscript/RegenerateTranscript";
import OpsModal from "../../../components/Modal/OppsModal/OpsModal";

const EditVocabList = () => {
  const vocabList = useSelector((state) => state.vocab.vocabList);
  const { formData } = useContext(FormContext);
  const { currentUser } = useContext(UserContext);

  const dispatch = useDispatch();
  const [displayedList, setDisplayedList] = useState(0);
  const [focusedKeywordIndex, setFocusedKeywordIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showRegenerateVocabularyModal, setShowRegenerateVocabularyModal] =
    useState(false);
  const [highlightedCardIndex, setHighlightedCardIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showCompleteVocabularyModal, setShowCompleteVocabularyModal] =
    useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const cardRefs = useRef([]);
  const [temp, setTemp] = useState([]); // New array for last 3 items
  const [regenerationCounts, setRegenerationCounts] = useState({}); // State to track counts
  const [keywords, setKeywords] = useState([]); // State to track counts
  const [showTestNameModal, setShowTestNameModal] = useState(false);
  const [showErrorModal, setShowerrorModal] = useState(false);

  const [totalRegenerationCount, setTotalRegenerationCount] = useState(0); // Use state for regeneration count
  const maxRegenerations = 3;
  useEffect(() => {
    if (vocabList[displayedList]?.length > formData.vocabularyCount) {
      const tempArray = vocabList[displayedList].slice(-3);
      const updatedVocabList = vocabList[displayedList].slice(0, -3);

      setTemp(tempArray);
      dispatch(
        setVocabList([...vocabList.slice(0, displayedList), updatedVocabList])
      );
      setKeywords(updatedVocabList);
    }
  }, [vocabList, displayedList, dispatch, formData]);

  useEffect(() => {
    if (location.state && location.state.index !== undefined) {
      setFocusedKeywordIndex(location.state.index);
    }
  }, [location.state]);

  useEffect(() => {
    if (focusedKeywordIndex !== null && cardRefs.current.length > 0) {
      const index = focusedKeywordIndex;
      if (index >= 0 && index < vocabList[displayedList]?.length) {
        cardRefs.current[index]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setHighlightedCardIndex(index);
      }
    }
  }, [focusedKeywordIndex, displayedList, vocabList]);

  const handleSaveClick = () => {
    dispatch(setVocabList(vocabList));
    toast.success("Vocabulary Edited Successfully");
    setFocusedKeywordIndex(null);
    setHighlightedCardIndex(null);
  };

  const handleCancelClick = () => {
    setFocusedKeywordIndex(null);
    setHighlightedCardIndex(null);
  };

  const handleDeleteClick = (index) => {
    setFocusedKeywordIndex(index);
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    if (focusedKeywordIndex === null || vocabList.length === 0) return;

    // Update vocabList by removing the item at focusedKeywordIndex
    const updatedVocabList = vocabList.map((list, listIndex) =>
      listIndex === displayedList
        ? list.filter((_, itemIndex) => itemIndex !== focusedKeywordIndex)
        : list
    );

    // Ensure the updated list is valid and fallback to empty arrays where needed
    const validVocabList = updatedVocabList.map((list) => list ?? []);
    setKeywords(validVocabList[displayedList] ?? []); // Ensure keywords array is valid

    // Dispatch valid vocabList
    dispatch(setVocabList(validVocabList)); // Ensure this is uncommented

    // Close the modal and reset indexes
    setShowModal(false);
    toast.success("Vocabulary Deleted Successfully");
    setHighlightedCardIndex(null);
    setFocusedKeywordIndex(null);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFocusedKeywordIndex(null);
    setHighlightedCardIndex(null);
  };

  // Function to view complete vocabulary
  const handleViewCompleteVocabulary = () => {
    setShowCompleteVocabularyModal(true);
  };

  // Function to handle input changes
  const handleChange = (index, field, value) => {
    // Clone the entire keywords array to avoid direct mutation
    const updatedVocabList = [...keywords]; // Create a shallow copy of the array

    // Clone the specific item that is being updated to avoid direct mutation
    const updatedItem = { ...updatedVocabList[index] };

    // Update the field (e.g., source_language or target_language) with the new value
    updatedItem[field] = value;

    // Replace the old item with the updated one in the cloned array
    updatedVocabList[index] = updatedItem;

    // Dispatch the new vocab list to the Redux store or set local state
    console.log("updatedVocabList", updatedVocabList);
    setKeywords(updatedVocabList);
    // dispatch(setVocabList([...vocabList.slice(0, displayedList), ...updatedVocabList]));
  };

  const handleNavigateHistory = (index, direction) => {
    dispatch(
      navigateKeywordHistory({
        listIndex: displayedList,
        keywordIndex: index,
        direction,
      })
    );
  };

  const handleTestNameSubmit = (name) => {
    setShowTestNameModal(false);
    submitClicked(name);
  };
  const showRegenerateVocabulary = () => {
    if (totalRegenerationCount >= maxRegenerations) {
      setShowerrorModal(true);
    } else {
      setShowRegenerateVocabularyModal(true);
    }
  };

  const handleRegenerate = (index) => {
    setTotalRegenerationCount((prevTotalCount) => {
      if (prevTotalCount >= maxRegenerations) {
        // toast.error("No keywords available for regeneration.");

        setShowerrorModal(true);
        return prevTotalCount; // Return the old value to avoid updating
      }

      const currentCount = regenerationCounts[index] || 0;

      if (temp.length > 0) {
        const newKeyword = temp[0]; // New keyword to replace the current one

        const updatedKeywords = [...keywords];
        updatedKeywords[index] = newKeyword; // Replace with new keyword
        toast.success("Vocabulary Generated Successfully");

        setKeywords(updatedKeywords);
        setTemp((prevTemp) => prevTemp.slice(1));

        setRegenerationCounts((prevCounts) => ({
          ...prevCounts,
          [index]: currentCount + 1,
        }));

        return prevTotalCount + 1; // Increment and return the updated count
      } else {
        toast.error("No keywords available for regeneration.");
        return prevTotalCount; // No change in total count
      }
    });
  };

  const regenerateVocabulary = async (feedbacks) => {
    setLoading(true);
    const maxRetries = 2;
    let attempts = 0;
    let success = false;

    // Using the current state of totalRegenerationCount to check the limit
    if (totalRegenerationCount >= maxRegenerations) {
      // toast.error("No keywords available for regeneration.");
      setShowerrorModal(true);
      setLoading(false);
      return;
    }

    while (attempts < maxRetries && !success) {
      try {
        const data = await generateVocabulary(
          currentUser.access,
          formData,
          formData.region,
          feedbacks,
          REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE
        );

        if (
          data &&
          data.keywords &&
          data.keywords.keywords &&
          Array.isArray(data.keywords.keywords.lists)
        ) {
          console.log(
            "data.keywords.keywords.lists",
            data.keywords.keywords.lists[0].length
          );

          dispatch(setVocabList(data.keywords.keywords.lists));
          success = true;
          toast.success("Vocabulary generated Successfully");

          setTotalRegenerationCount((prevCount) => {
            const newCount = prevCount + 1;

            return newCount; // Return the new count
          });

          setShowRegenerateVocabularyModal(false);
          setLoading(false);
          break;
        } else {
          setShowModal(false);
          console.error("Invalid data format", data);
        }
        break;
      } catch (error) {
        setShowModal(false);
        console.error(
          `API call failed (Attempt ${attempts + 1}/${maxRetries}):`,
          error
        );
        attempts += 1;

        if (attempts >= maxRetries) {
          toast.error(
            "Error in Generating. Go back to Dashboard and please try again later."
          );
          setShowModal(false);
        } else {
          console.log("Retrying the API call...");
        }
      }
    }
  };

  const submitClicked = async (testName) => {
    // const accessToken = localStorage.getItem("accessToken");

    setLoading(true);
    // const Userid = localStorage.getItem("email");

    const practiceInfo = {
      topic: formData.topic,
      source_language: formData.sourceLanguage,
      target_language: formData.targetLanguage,
      level: formData.level,
      region: formData.region,
      num_keywords: parseInt(formData.vocabularyCount, 10),
      num_lists: REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE,
      user_id: currentUser.email,
      practice_name: testName,
    };
    // const keywords = vocabList[displayedList];
    const dataToSubmit = {
      practice_info: practiceInfo,
      keywords: keywords,
    };

    try {
      const result = await saveVocabPractice(dataToSubmit, currentUser.access);

      // console.log("Success:", result);

      localStorage.removeItem("vocabState");
      toast.success("Vocabulary Saved Successfully");
      navigate(`/congratulation/${result.practice_info.identifier}`);
    } catch (error) {
      if (error.message.includes("already in use")) {
        // setErrorMessage(
        //   "The practice name you entered is already in use. Please choose a different practice name."
        // );
        toast.error(
          "The practice name you entered is already in use. Please choose a different practice name."
        );
        setShowTestNameModal(true);
      } else {
        console.error("Error:", error);
        toast.error("An unexpected error occurred. Please try again later.");
        // setErrorMessage(
        //   "An unexpected error occurred. Please try again later."
        // );
      }
    } finally {
      setLoading(false);
    }
  };
  const closemodal = () => {
    setShowerrorModal(false);
    setShowRegenerateVocabularyModal(false);
  };
  return (
    <div className="min-screen edit-list">
      {loading && <Loader />}
      {showModal && (
        <ConfirmDeleteModal
          showModal={showModal}
          onClose={handleCloseModal}
          onDelete={handleConfirmDelete}
          message={"Are you sure you want to delete this Keyword?"}
        />
      )}
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="container">
          <div className="edit-contnet">
            <h1 className="title">Edit Vocabulary List </h1>
            <div className="scrollbar">
              <div className="viewBox">
                <button
                  className="submit mb-2"
                  onClick={() => showRegenerateVocabulary()}
                >
                  Full Regenerate List
                </button>
                <button className="lightVocab">
                  <Link
                    className="noDecoration"
                    onClick={handleViewCompleteVocabulary}
                  >
                    View complete vocabulary
                  </Link>
                </button>

                <button
                  className="submit mb-2"
                  onClick={() => setShowTestNameModal(true)}
                >
                  Submit
                </button>
              </div>
              {keywords?.length > 0 ? (
                keywords.map((item, index) => (
                  <div
                    className={`card ${
                      index === highlightedCardIndex ? "highlighted" : ""
                    }`}
                    key={index}
                    ref={(el) => (cardRefs.current[index] = el)}
                  >
                    <div className="card-body">
                      <div className="input-box">
                        <label className="form-label">Keyword </label>
                        <input
                          type="text"
                          className="form-control input-center"
                          value={item.source_language}
                          readOnly={index !== focusedKeywordIndex}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "source_language",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="input-box">
                        <label className="form-label">Meaning</label>
                        <textarea
                          rows="3"
                          cols="50"
                          className="form-control"
                          value={item.target_language}
                          readOnly={index !== focusedKeywordIndex}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "target_language",
                              e.target.value
                            )
                          }
                        ></textarea>
                      </div>
                      <div className="btn-list">
                        {index === focusedKeywordIndex ? (
                          <>
                            <button
                              className="btn btn-success"
                              onClick={handleSaveClick}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={handleCancelClick}
                            >
                              Cancel
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="btn btn-success"
                              onClick={() => setFocusedKeywordIndex(index)}
                            >
                              Edit
                            </button>
                            <div className="regenratebutton">
                              {" "}
                              {item.history && item.currentIndex > 0 && (
                                <button
                                  className="btn btn-secondary roundbtn"
                                  onClick={() =>
                                    handleNavigateHistory(index, "previous")
                                  }
                                >
                                  <img src={images.ArrowLeft} alt="ArrowLeft" />
                                  <span className="tooltip">
                                    Previous Vocabulary
                                  </span>
                                </button>
                              )}
                              <button
                                className="btn btn-primary "
                                onClick={() => handleRegenerate(index)}
                              >
                                Regenerate
                              </button>
                              {item.history &&
                                item.currentIndex < item.history.length - 1 && (
                                  <button
                                    className="btn btn-secondary roundbtn"
                                    onClick={() =>
                                      handleNavigateHistory(index, "next")
                                    }
                                  >
                                    <span className="tooltip">
                                      Next Vocabulary
                                    </span>
                                    <img
                                      src={images.ArrowRight}
                                      alt="ArrowRight"
                                    />
                                  </button>
                                )}
                            </div>
                            <button
                              className="btn btn-danger"
                              onClick={() => handleDeleteClick(index)}
                            >
                              Delete
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No vocabulary found.</p>
              )}
            </div>
            <div className="btn-submit-list">
              <button
                className="submit"
                onClick={() => setShowTestNameModal(true)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <Footer />
        {showTestNameModal && (
          <TestNameModal
            show={showTestNameModal}
            name={""}
            onClose={() => setShowTestNameModal(false)}
            onSubmit={handleTestNameSubmit}
            errorMessage={(errorMessage) => toast.error(errorMessage)}
          />
        )}
        {showCompleteVocabularyModal && (
          <VocabListModal
            vocabList={keywords}
            url={`/edit-vocab-list`}
            onClose={() => setShowCompleteVocabularyModal(false)}
          />
        )}

        {showRegenerateVocabularyModal && (
          <RegenerateTranscript
            show={showRegenerateVocabularyModal}
            name={""}
            type={"Vocabulary"}
            onClose={() => setShowRegenerateVocabularyModal(false)}
            onSubmit={regenerateVocabulary}
            errorMessage={(errorMessage) => toast.error(errorMessage)}
          />
        )}
        {showErrorModal && (
          <OpsModal
            show={showErrorModal}
            // onNextStep={handleNextStep}
            onClose={closemodal}
            model=" Vocabulary"
            onNextStep={closemodal}
          />
        )}
      </div>
    </div>
  );
};

export default EditVocabList;
