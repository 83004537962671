import React, { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import HeaderComponent from "components/Header/Header";
import { FormContext } from "../../../providers/FormContext";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer/Footer";

const TranscriptSpeaker = () => {
  const { formData, updateFormData } = useContext(FormContext);
  const navigate = useNavigate();
  const { id } = useParams();

  const [speaker, setSpeaker] = useState(formData.speaker_info || "");
  const [person1, setPerson1] = useState(formData.person1 || "");
  const [person2, setPerson2] = useState(formData.person2 || "");

  const [conversationType] = useState(formData.conversationType || "");

  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSpeaker(value);
    updateFormData("speaker_info", value);
  };

  const handleInputSpeaker1Change = (e) => {
    const value = e.target.value;
    setPerson1(value);
    updateFormData("person1", value);
  };

  const handleInputSpeaker2Change = (e) => {
    const value = e.target.value;
    setPerson2(value);
    updateFormData("person2", value);
  };

  const validateInput = () => {
    if(conversationType === "speech"){
      if (speaker.trim() === "") {
        setError("Speaker information cannot be empty.");
        toast.error("Speaker information cannot be empty.");
        return false;
      }
      setError("");
      return true;
    }else{
      if (person1.trim() === "") {
        setError("Person1 information cannot be empty.");
        toast.error("Person1 information cannot be empty.");
        return false;
      }
      if (person2.trim() === "") {
        setError("Person2 information cannot be empty.");
        toast.error("Person2 information cannot be empty.");
        return false;
      }
      setError("");
      return true;
    }
    
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    if (validateInput()) {
      navigate(`/transcript-additional-info/${id}`);
    }
  };

  return (
    <div className="min-screen screen13">
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="main-content">
          <h1 className="title">Transcript Generation</h1>
          <p className="des">
            Please identify the speaker to help us accurately attribute and
            organize the dialogue in the transcript. Providing the speaker's
            name or role will enhance the clarity and usability of the
            transcript.
          </p>
          <form className="form_">

          { conversationType === "speech" ? 
            <>
              <label className="form-label">Who is the speaker?</label>
              <input
                className="form-control"
                // rows="3"
                value={speaker}
                onChange={handleInputChange}
                placeholder="Please specify the speaker or scenario for the transcript. (Ex: commencement speech speaker, politician at a campaign rally, CEO addressing shareholders, therapist in a counseling session, chef explaining a recipe, etc.)"
                required
                />
            </>
            :
            <>
              <label className="form-label">Person 1 (speaker)</label>
              <input
                className="form-control"
                value={person1}
                onChange={handleInputSpeaker1Change}
                placeholder="Person 1 (speaker)."
                required
                /> 

              <label className="form-label">Person 2</label>
              <input
                className="form-control"
                value={person2}
                onChange={handleInputSpeaker2Change}
                placeholder="Person 2."
                required
                />
            </>
          }
          </form>
          {error && (
            <div className="trans-error-message">
              <span>{error}</span>
            </div>
          )}
          <div className="btn-list">
            <Link to={`/transcript-type/${id}`} className="noDecoration">
              <button className="small">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                </svg>
                Back
              </button>
            </Link>
            <Link className="noDecoration">
              <button className="small" onClick={handleNextClick}>
                next
                <svg
                  xmlns="http:www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                </svg>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TranscriptSpeaker;
