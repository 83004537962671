import React from "react";
import "./OpsModal.css";
import images from "../../../images";
const OpsModal = ({ show, onNextStep, onClose, model }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="Opsmodal" onClick={onClose}>
      <div className="Opsmodal-content" onClick={(e) => e.stopPropagation()}>
        <div className="Opsmodal-icon">
          <img src={images.sadface} alt="Sad face icon" />

          <span className="close-button" onClick={onClose}>
            &times;
          </span>
        </div>
        <h2 style={{ color: "#17353B", fontWeight: "700" }}>OOPS!</h2>
        <p style={{ color: "#A2A2A2" }}>
          Oops, you have reached the maximum number of attempts to regenerate
          the {model}.
        </p>
        <div>
          <button id="regenerate-button" onClick={onNextStep}>
            Next Step
          </button>
        </div>
      </div>
    </div>
  );
};

export default OpsModal;
