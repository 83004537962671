import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderComponent from "components/Header/Header";
import { Button } from "react-bootstrap";

import { useDispatch } from "react-redux";
import {
  addTempTranscript,
  addTranscriptToHistory,
  clearTempTranscript,
  clearTranscriptHistory,
  setTranscriptText,
  updateIdentifier,
} from "../../../redux/vocabSlice";
import ModalComponent from "../../../components/Modal/VocabModal/VocabModal";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer/Footer";
import {
  fetchTranscriptService,
  submitTranscriptData,
} from "../../../services/transcript.service";
import { UserContext } from "../../../providers/UserContext";
import ErrorModalComponent from "../../../components/Modal/TryAgainModal/TryAgainModal";
import Select from "react-select";

const EditTranscript = () => {
  const [activeStep, setActiveStep] = useState("transcript-words");
  const [showModal, setShowModal] = useState(false);
  const [transcript, setTranscript] = useState([]);
  const { currentUser } = useContext(UserContext);
  const [usertoken] = useState(currentUser.access || "");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [error, setError] = useState("");
  const [showErorModal, setShowErorModal] = useState(false);

  const steps = [
    {
      primaryStep: true,
      stepName: "transcript-words",
      component: "",
      heading: "Transcript Generation",
      content: `Please enter the word count to help us generate accurate and comprehensive transcripts. Providing the word length will assist us in delivering better results for your transcription needs..`,
    },
    {
      primaryStep: false,
      stepName: "conversation-type",
      component: "",
      heading: "Transcript Conversation Type",
      content: `Please select the transcript type to help us tailor your transcription accurately. Choosing the appropriate type will enhance the clarity and relevance of the final transcript.`,
    },
    {
      primaryStep: false,
      stepName: "speaker",
      component: "",
      heading: "Transcript Generation",
      content: `lease identify the speaker to help us accurately attribute and organize the dialogue in the transcript. Providing the speaker's name or role will enhance the clarity and usability of the transcript.`,
    },
    {
      primaryStep: false,
      stepName: "additional-instructions",
      component: "",
      heading: "Transcript Generation",
      content: `Is there anything else you would like to let us know? Please provide any additional information or special instructions that will help us generate the most accurate and useful transcript for your needs.`,
    },
  ];

  const ConversationOptions = [
    { 
      value: "speech",
      label: "Speech"
    },
    {
      value: "conversation",
      label: "Addressing an Individual"
    }
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgba(18, 38, 63, 0.9)", // Closer to dark blue in your image
      borderRadius: "10px", // Slightly more rounded corners
      borderColor: "rgba(255, 255, 255, 0.15)", // Soft white border
      color: "#fff",
      padding: "4px 8px",
      boxShadow: "0 3px 15px rgba(0, 0, 0, 0.2)", // Softer shadow for a subtle lift
      minHeight: "48px", // Adjust height as needed
      fontWeight: 400, // Regular font weight
      fontSize: "16px", // Standard text size
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "rgba(18, 38, 63, 0.9)", // Matching dark blue with high transparency
      borderRadius: "10px",
      boxShadow: "0 3px 15px rgba(0, 0, 0, 0.25)", // Consistent shadow with control
      borderColor: "rgba(255, 255, 255, 0.15)",
      overflow: "hidden", // Ensures the rounded corners contain all child elements
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgb(120, 124, 137)"
        : "rgb(70, 75, 92)",
      color: "#FFF",
      padding: "15px 20px",
      margin: "8px",
      maxWidth: "1100px",
      borderRadius: "15px",
      // Generous padding for a spacious feel
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#FFF",
    }),
    input: (provided) => ({
      ...provided,
      color: "#FFF", // White text for input
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#CCC", // Placeholder text color for better contrast
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white", // Consistent white arrow
      "&:hover": {
        color: "rgba(255, 255, 255, 0.75)", // Slightly dim on hover for a dynamic effect
      },
    }),
    indicatorSeparator: () => ({
      display: "none", // Cleaner look without the separator
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0px", // Remove padding to match your aesthetic requirements
    }),
  };

  const closeModal = () => {
    setShowErorModal(false);
  };
  useEffect(() => {
    if (currentUser && currentUser.access) {
      fetchTranscriptText();
    }
    // console.log(transcript, "abdhs");
  }, [id, currentUser]);

  useEffect(() => {
    dispatch(clearTempTranscript());
    dispatch(clearTranscriptHistory());
    if (id) {
      dispatch(updateIdentifier(id));
    }
  }, [dispatch]);

  // const accessToken = localStorage.getItem("accessToken");

  const fetchTranscriptText = async () => {
    try {
      const data = await fetchTranscriptService(id, currentUser.access);

      if (data && data.transcript) {
        setTranscript(data.transcript);
        // setAudios(data.temporary_audios);
      } else {
        toast.error("NO Transcript found");
        // setTranscript("Transcript not found.");
      }
    } catch (error) {
      console.error("Error fetching the transcript:", error);
      // setTranscript("Error fetching the transcript.");
    }
  };

  const handleInputChange = (selectedOption, field) => {
    // Always update the state, trimming the input only if it's non-empty
    setTranscript((prevDetails) => ({
      ...prevDetails,
      [field]: selectedOption ? selectedOption : "", // If empty, set an empty string
    }));
  };

  const getCurrentStepIndex = () => {
    return steps.findIndex((step) => step.stepName === activeStep);
  };

  const handleNext = async () => {
    setShowErorModal(false);
    if (activeStep === "transcript-words") {
      const length = parseInt(transcript.transcript_length, 10);

      if (transcript.transcript_length === "") {
        toast.error("Transcript length cannot be empty.");
        return false;
      }

      if (isNaN(length)) {
        toast.error("Please enter a valid number.");
        return false;
      }

      if (length <= 0) {
        toast.error("Please enter a positive number.");
        return false;
      }

      if (length < 50) {
        toast.error("Please enter a minimum 50 words desired length.");
        return false;
      }

      if (length > 1500) {
        toast.error("The maximum allowed length is 1500.");
        return false;
      }
    } else if (activeStep === "speaker") {
      if(transcript.conversation_type === "speech"){
        if (transcript.speaker === "") {
          setError("Speaker information cannot be empty.");
          toast.error("Speaker information cannot be empty.");
          return false;
        }
        
      }else{
        if (transcript.person1 === "") {
          toast.error("Person1 information cannot be empty.");
          return false;
        }
        if (transcript.person2 === "") {
          toast.error("Person2 information cannot be empty.");
          return false;
        }
 
      }
    } else if (activeStep === "conversation-type") {
      if (transcript.conversation_type === "") {
        toast.error("Conversation Type information cannot be empty.");
        return false;
      }
    } else if (activeStep === "additional-instructions") {
      if (transcript.additional_instructions === "") {
        toast.error("Additional information cannot be empty.");
        return false;
      }
    }

    const currentIndex = getCurrentStepIndex();
    if (currentIndex < steps.length - 1) {
      setActiveStep(steps[currentIndex + 1].stepName);
    }
    if (currentIndex === steps.length - 1) {
      console.log("transcript", transcript);
      setShowModal(true);
      // const token = localStorage.getItem("accessToken");
      if (!currentUser.access) {
        console.error("No access token found");
        setShowModal(false);
        return;
      }
      const dataToSend = {
        identifier: id,
        transcript_length: transcript.transcript_length,
        additional_instructions: transcript.additional_instructions,
        conversation_type:transcript.conversation_type,
        speaker: transcript.speaker,
        person1: transcript.person1,
        person2: transcript.person2,
        feedbacks:"",
      };
      const maxRetries = 2;
      let retryCount = 0;
      let success = false;

      while (retryCount <= maxRetries && !success) {
        try {
          // const response = await fetch(
          //   `${REACT_APP_UPDATE_TRANSCRIPTS}${id}/`,
          //   transcript
          //   {
          //     method: "PUT",
          //     headers: {
          //       "Content-Type": "application/json",
          //       Authorization: `Bearer ${accessToken}`,
          //     },
          //     body: JSON.stringify(dataToSend),
          //   }
          // );

          const result = await submitTranscriptData(
            dataToSend,
            currentUser.access
          );

          const transcriptUpdated = {
            ...transcript,
            transcript: result.transcript,
          };
          success = true;
          dispatch(addTempTranscript(transcriptUpdated));
          dispatch(setTranscriptText(result.transcript));
          dispatch(
            addTranscriptToHistory({
              transcript: result.transcript,
              identifier: id,
            })
          );
          toast.success("Transcript generated");
          navigate(`/transcript/generated/${id}`);
        } catch (error) {
          console.error("Error submitting data:", error);
          retryCount = 3;
          if (retryCount > maxRetries) {
            toast.error(
              `Something went wrong! Please try again after some time.`
            );
            setError("Something went wrong! Please try again after some time.");
            setTimeout(() => {
              // navigate("/practice-dashboard");
            }, 2000);
            break;
          }
        } finally {
          retryCount = 3;
          if (success || retryCount > maxRetries) {
            setShowModal(false);
          }
        }
      }
    }
  };

  const handleBack = () => {
    const currentIndex = getCurrentStepIndex();
    if (currentIndex > 0) {
      setActiveStep(steps[currentIndex - 1].stepName);
    }
  };

  return (
    <div className="min-screen screen13">
      <HeaderComponent />

      <div className="mainContentBox">
        {showModal && (
          <ModalComponent modalText="Generating Transcript. Please wait..." />
        )}
        <div className="main-content">
          <h1 className="title">Transcript Generation</h1>
          {steps.map(
            (step, index) =>
              activeStep === step.stepName && (
                <React.Fragment key={index}>
                  {" "}
                  {/* Added a unique key */}
                  <p className="des">{step.content}</p>
                  {activeStep === "transcript-words" && (
                    <form className="form_">
                     
                          <label className="form-label">
                            How long should the transcript be?{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            value={transcript.transcript_length}
                            onChange={(e) =>
                              handleInputChange(e.target.value, "transcript_length")
                            }
                            placeholder="Please enter the desired length of the transcript in words. (Ex: 500)"
                            required
                          />
                       
                        
                    </form>
                  )}
                  
                  {activeStep === "conversation-type" && (
                    <form className="form_">
                      <label className="form-label">
                        Select the transcript type for tailored, accurate results
                      <span className="text-danger">*</span>
                      </label>
                      <Select
                            options={ConversationOptions}
                            onChange={(e) =>
                              handleInputChange(e.value, "conversation_type")
                            }
                            placeholder="Select conversation type"
                            styles={customStyles}
                            defaultValue={
                              transcript.conversation_type
                                ? {
                                    value: transcript.conversation_type,
                                    label: transcript.conversation_type,
                                  }
                                : null
                            }
                          />
                    </form>
                  )}
                  {activeStep === "speaker" && (
                    <form className="form_">
                       { transcript.conversation_type === "speech" ? 
                        <>
                          <label className="form-label">Who is the speaker?</label>
                          <input
                            className="form-control"
                            rows="3"
                            value={transcript.speaker}
                            onChange={(e) =>
                              handleInputChange(e.target.value, "speaker")
                            }
                            placeholder="Please specify the speaker or scenario for the transcript."
                            required
                          />
                        </>
                      :
                        <>
                          <label className="form-label">Person 1 (speaker)</label>
                          <input
                            className="form-control"
                            value={transcript.person1}
                            onChange={(e) =>
                              handleInputChange(e.target.value, "person1")}
                            placeholder="Person 1 (speaker)."
                            required
                            /> 
            
                          <label className="form-label">Person 2</label>
                          <input
                            className="form-control"
                            value={transcript.person2}
                            onChange={(e) =>
                              handleInputChange(e.target.value, "person2")
                            }
                            placeholder="Person 2."
                            required
                            />
                         </>
                      }
                    </form>
                  )}
                  {activeStep === "additional-instructions" && (
                    <form className="form_">
                      <label className="form-label">
                        Is there anything else you would like to let us know?{" "}
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <textarea
                        className="form-control"
                        rows="3"
                        value={transcript.additional_instructions}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            "additional_instructions"
                          )
                        }
                        placeholder="Additional instructions or preferences for the transcript?"
                        required
                      ></textarea>
                    </form>
                  )}
                </React.Fragment>
              )
          )}

          {error && (
            <div className="trans-error-message">
              <span>{error}</span>
            </div>
          )}
          <div className="btn-list">
            <Button className="small" onClick={handleBack}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="currentColor"
              >
                <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
              </svg>
              Back
            </Button>

            <Button className="small" onClick={handleNext}>
              next
              <svg
                xmlns="http:www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="currentColor"
              >
                <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
              </svg>
            </Button>
          </div>
        </div>
      </div>
      <Footer />
      {showErorModal && (
        <ErrorModalComponent
          modalText="Something went wrong! Please try again."
          onClose={closeModal}
          onClickTryAgain={handleNext}
        />
      )}
    </div>
  );
};

export default EditTranscript;
