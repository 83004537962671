import React, { useState } from "react";
import "./TestNameModal.css";
import { toast } from "react-toastify";

const TestNameModal = ({ show, name, onClose, onSubmit, errorMessage }) => {
  const [testName, setTestName] = useState(name);
  const [localError, setLocalError] = useState("");
  const handleInputChange = (e) => {
    setTestName(e.target.value);
    if (e.target.value.trim() !== "") {
      setLocalError("");
    }
  };

  const handleSubmit = () => {
    if (testName.trim() === "") {
      toast.error("Please enter a practice name.");
      // setLocalError("Please enter a practice name.");
      return;
    }
    onSubmit(testName);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="practise-modal" onClick={onClose}>
      <div
        className="practise-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="practise-close-button" onClick={onClose}>
          &times;
        </span>

        <h2 style={{ marginBottom: "20px" }}>Practice Name</h2>
        <div>
          <input
            type="text"
            placeholder="Enter practice test name"
            value={testName}
            className="practise-test-input"
            onChange={handleInputChange}
          />
        </div>
        {localError && <p className="practise-error-message">{localError}</p>}
        {errorMessage && !localError && (
          <p className="error-message">{errorMessage}</p>
        )}
        <div className="practise-test-submit">
          <button id="regenerate-button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestNameModal;
