import React, { useContext, useEffect, useRef, useState } from "react";
import "./VoiceCatalog.css";
import HeaderComponent from "../../../components/Header/Header";
import { useNavigate, useParams } from "react-router";
import images from "../../../images";
import customVoices from "../../../customVoices.json";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer/Footer";
import ModalComponent from "../../../components/Modal/VocabModal/VocabModal";
import { generateAudio } from "../../../services/audio.service";
import { fetchSourceLanguageService } from "../../../services/transcript.service";
import ErrorModalComponent from "../../../components/Modal/TryAgainModal/TryAgainModal";
import { UserContext } from "../../../providers/UserContext";
import OpsModal from "../../../components/Modal/OppsModal/OpsModal";
import languagesData from "../../../language.json";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal/ConfirmationModal";

const VoiceCatalog = () => {
  const [step, setStep] = useState(1); // Step 1: Voice selection, Step 2: Speed selection
  const [selectedCard, setSelectedCard] = useState(null);
  const [speed, setSpeed] = useState("");
  const { currentUser } = useContext(UserContext);
  const [usertoken] = useState(currentUser.access || "");
  const [wpm, setWpm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showErorModal, setShowErorModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [voices, setVoices] = useState([]);
  const [filteredVoices, setFilteredVoices] = useState([]);
  const navigate = useNavigate();
  const [audioUrl, setAudioUrl] = useState(null);
  const [chunks, setChunks] = useState([]);
  const [text, setText] = useState("");
  const { id } = useParams();
  const identifier = id;
  const [sourceLanguage, setSourceLanguage] = useState("");
  const [playingVoiceId, setPlayingVoiceId] = useState(null);
  const [playingStyle, setPlayingStyle] = useState(null);
  const audioRef = useRef(null); // Reference for the audio element
  const [audioInstance, setAudioInstance] = useState(null);
  const [showOpsModal, setOpsShowModal] = useState(false);
  const [temporaryAudios, setTemporaryAudios] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const closeModal = () => {
    setOpsShowModal(false);
    setShowErorModal(false);
  };
  const [selectedVoiceName, setSelectedVoiceName] = useState("");
  const [selectedStyleName, setSelectedStyleName] = useState("");
  const handlePlayPause = async (e, voice, styleSample) => {
    e.stopPropagation(); // Prevent event bubbling

    const currentVoiceId = `${voice.id}-${styleSample.styleName}`;

    // Check if the same voice is already playing
    if (playingVoiceId === currentVoiceId) {
      // If audioInstance is defined, pause it
      if (audioInstance) {
        await audioInstance.pause();
        setPlayingVoiceId(null); // Reset the playing state
      }
    } else {
      // If a different voice is playing, pause it
      if (audioInstance) {
        await audioInstance.pause();
      }

      // Create a new Audio instance with the selected styleSample's audio file URL
      const newAudioInstance = new Audio(styleSample.audioFileEndpointWithSas);

      // Play the new audio
      newAudioInstance.play().catch((error) => {
        console.error("Audio playback failed:", error);
      });

      // Set the new audio instance and the currently playing voice ID
      setAudioInstance(newAudioInstance);
      setPlayingVoiceId(currentVoiceId); // Use combined ID

      // Reset the playing state once the audio ends
      newAudioInstance.onended = () => {
        setPlayingVoiceId(null);
      };
    }
  };

  useEffect(() => {
    // Push a new entry to the history stack on mount
    window.history.pushState(null, document.title, window.location.href);

    const handlePopState = (event) => {
      // Check if the popstate event is triggered
      if (event.state) {
        // Redirect to the current page to prevent back navigation
        window.history.pushState(null, document.title, window.location.href);
        // Optionally, you can redirect to a specific page
        // window.location.replace("/your-specific-page");
      }
    };

    // Listen for popstate events
    window.addEventListener("popstate", handlePopState);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const onSearch = (e) => {
    setFilterText(e.target.value);
  };
  useEffect(() => {
    const fetchSourceLanguage = async () => {
      try {
        // const accessToken = localStorage.getItem("accessToken");
        const SourceLanguage = await fetchSourceLanguageService(
          currentUser.access,
          identifier
        );
        console.log("Full Source Language Object:", SourceLanguage.keyword);

        setSourceLanguage(SourceLanguage?.keyword.source_language);
        setFilterText(SourceLanguage?.keyword.source_language);
        // console.log(SourceLanguage?.keyword.source_language)
        const tempAudios = SourceLanguage?.temporary_audios || [];
        setTemporaryAudios(tempAudios);

        if (tempAudios.length > 3) {
          toast.info(
            "You have already generated more than 3 temporary audios."
          );
        }
        const normalizedSourceLanguage = sourceLanguage.trim().toLowerCase();

        // Filter voices based on source language
        const initialFilteredVoices = customVoices.filter((voice) =>
          voice.properties.LocaleDescription.trim()
            .toLowerCase()
            .includes(normalizedSourceLanguage)
        );

        setVoices(
          // initialFilteredVoices.length > 0
          //   ? initialFilteredVoices
          customVoices
        );
        setFilteredVoices(
          initialFilteredVoices.length > 0
            ? initialFilteredVoices
            : customVoices
        );
      } catch (error) {
        console.error(
          "Error fetching source language:",
          error.response || error.message
        );
        toast.error("An error occurred while fetching the source language.");
      }
    };

    if (currentUser && currentUser.access) {
      fetchSourceLanguage();
    }
  }, [currentUser]);

  useEffect(() => {
    if (filterText) {
      // Find the language based on the user's input (filterText)
      const [language] = languagesData.filter((lang) =>
        lang.fullname.toLowerCase().includes(filterText.toLowerCase())
      );
      console.log("language", language);
      // Check if we found a matching language and if it has a shortname
      if (language && language.shortname) {
        let shortname = language.shortname.toLowerCase();
        let fullname = language.fullname.toLowerCase();
        // Filter voices by matching Locale, DisplayName, or LocaleDescription
        const filtered = voices.filter((voice) => {
          const displayName =
            voice?.properties?.DisplayName?.toLowerCase() || "";
          const locale = voice?.locale?.toLowerCase() || "";
          const localeDescription =
            voice?.properties?.LocaleDescription?.toLowerCase() || "";

          // Return true if any of these fields match shortname or fullname
          return (
            // displayName===(shortname) ||
            // displayName===(fullname) ||
            locale === shortname ||
            // locale.includes(fullname) ||
            // localeDescription.includes(shortname) ||
            localeDescription.includes(fullname)
          );
        });

        setFilteredVoices(filtered);
      } else {
        setFilteredVoices(voices); // Clear the filtered list if no match is found
      }
    } else {
      setFilteredVoices(voices); // Clear the filtered list if no match is found
    }
  }, [filterText, voices]);

  const GenerateAudio = async (cardId, e) => {
    // const accessToken = localStorage.getItem("accessToken");
    setShowErorModal(false);

    if (temporaryAudios.length >= 3) {
      setOpsShowModal(true);

      return;
    }
    if (!selectedCard) {
      toast.error("Please select a voice card before generating audio.");
      return;
    }

    if (!speed) {
      toast.error("Please select an audio speed before generating audio.");
      return;
    }

    setShowModal(true);

    try {
      // const selectedVoiceName = cardId?.shortname;
      // console.log(selectedVoiceName, "selectedVoiceName");
      // const selectedStyleName = cardId.emotionStyle;
      // return;
      const dataToSend = {
        identifier: identifier,
        voice: selectedVoiceName,
        speed: speed,
        wpm: wpm,
        style_name: selectedStyleName,
      };

      const response = await generateAudio(
        currentUser.access,
        identifier,
        selectedVoiceName,
        speed,
        wpm,
        selectedStyleName
      );
      setShowModal(false);
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "audio/mpeg" });
        const audioURL = URL.createObjectURL(blob);
        console.log("Generated audio URL:", audioURL);
        setAudioUrl(audioURL);

        const words = text.split(" ");
        const chunkSize = 25;
        const newChunks = [];
        for (let i = 0; i < words.length; i += chunkSize) {
          newChunks.push(words.slice(i, i + chunkSize).join(" "));
        }
        setChunks(newChunks);
        toast.success("Audio  generated Successfully");
        navigate(`/transcript-voice/${id}`, {
          state: dataToSend,
        });
      } else {
        setShowModal(false);
        // console.error("Failed to generate audio:", response.statusText);
        setShowErorModal(true);
      }
    } catch (error) {
      console.error("Error generating audio:", error);
      setShowModal(false);
      setShowErorModal(true);
      // console.error("Error generating audio:", error.response || error.message);
    }
  };

  // Triggered when a card is clicked
  const handleCardClick = (voiceId, emotionStyle, shortname) => {
    // Store the temporary values to set once the user confirms
    setSelectedCard(`${voiceId}-${emotionStyle}-${shortname}`);
    setSelectedVoiceName(shortname);
    setSelectedStyleName(emotionStyle);

    // Show the confirmation modal
    setShowConfirmationModal(true);
  };

  // When the user confirms the selection
  const handleConfirmSelection = () => {
    // Close the modal
    setShowConfirmationModal(false);
    setStep(2); // Move to Step 2
  };

  // When the user cancels the selection
  const handleCancelSelection = () => {
    // Close the modal without doing anything
    setShowConfirmationModal(false);
  };

  const handleSpeedChange = (speedValue, wpm) => {
    setSpeed(speedValue);
    setWpm(wpm);
    console.log("Selected Speed:", speedValue);
  };

  const nextStep = () => {
    navigate(`/transcript-voice/${id}`);
  };

  const isSelected = (voice) => selectedCard && selectedCard.id === voice.id;

  return (
    <div className={`min-screen ${step === 1 ? "Voice" : "VoiceSpeed"}`}>
      <HeaderComponent />
      <div className="mainContentBox">
        {showModal && (
          <ModalComponent modalText="Generating Audio. Please wait..." />
        )}
        <div className="container">
          {step === 1 && (
            <>
              <div className="voice-catlog">
                <h2>Voice Catalog</h2>
              </div>
              <div className="voice-catalog-section">
                <div className="voice-catlog-header">
                  <span> Voice Catalog List</span>
                  <input
                    type="text"
                    className=" form-control input-search"
                    placeholder="Type to Filter voices..."
                    value={filterText}
                    onChange={(e) => onSearch(e)}
                  />
                </div>
                <div className="voice-catalog-list">
                  {/* {filteredVoices.map((voice) =>
                  voice.samples.styleSamples.map((styleSample) => (
                    <div
                      key={`${voice.id}-${styleSample.styleName}`}
                      className={`voice-card ${
                        selectedCard?.id === voice.id ? "selected" : ""
                      }`}
                      onClick={() => setSelectedCard(voice)}
                    >
                      <div className="voice-details">
                        <img
                          src={
                            voice.properties.Gender === "Male"
                              ? images.maleLogo
                              : images.femaleLogo
                          }
                          alt="Voice Icon"
                          className="voice-icon"
                        />
                        <div className="Card-blok">
                          <p className="headOne">
                            {voice.properties.LocaleDescription}
                          </p>
                          <p
                            className={`headeTwo ${
                              isSelected(voice) ? "selected-text" : ""
                            }`}
                          >
                            {voice.properties.DisplayName}
                          </p>
                          <p className="headThree">
                            Voice Style: {styleSample.styleName}
                          </p>
                        </div>
                      </div>

                      <button
                        className={`play-sample-button ${
                          playingVoiceId === voice.id ? "playing" : ""
                        }`}
                        onClick={(e) => handlePlayPause(e, voice)}
                      >
                        {playingVoiceId === voice.id
                          ? "PAUSE SAMPLE"
                          : "PLAY SAMPLE"}
                        <img
                          src={images.PlayAudio}
                          style={{ marginLeft: "8px" }}
                          alt="play audio"
                        />
                      </button>
                    </div>
                  ))
                )} */}
                  {filteredVoices.map((voice) =>
                    voice.samples.styleSamples.map((styleSample) => (
                      <div
                        key={`${voice.id}-${styleSample.styleName}`}
                        className={`voice-card ${
                          selectedCard ===
                          `${voice.id}-${styleSample.styleName}-${voice.properties.ShortName}`
                            ? "selected"
                            : ""
                        }`}
                        onClick={() =>
                          handleCardClick(
                            voice.id,
                            styleSample.styleName,
                            voice.properties.ShortName
                          )
                        }
                      >
                        <div className="voice-details">
                          <img
                            src={
                              voice.properties.Gender === "Male"
                                ? images.maleLogo
                                : images.femaleLogo
                            }
                            alt="Voice Icon"
                            className="voice-icon"
                          />
                          <div className="Card-blok">
                            <p className="headOne">
                              {voice.properties.LocaleDescription}
                            </p>
                            <p
                              className={`headeTwo ${
                                isSelected(
                                  `${voice.id}-${styleSample.styleName}-${voice.properties.ShortName}`
                                )
                                  ? "selected-text"
                                  : ""
                              }`}
                            >
                              {voice.properties.DisplayName}
                            </p>
                            <p className="headThree">
                              Voice Style: {styleSample.styleName}
                            </p>
                          </div>
                        </div>

                        <button
                          className={`play-sample-button ${
                            playingVoiceId ===
                            `${voice.id}-${styleSample.styleName}`
                              ? "playing"
                              : ""
                          }`}
                          onClick={(e) =>
                            handlePlayPause(e, voice, styleSample)
                          }
                        >
                          {playingVoiceId ===
                          `${voice.id}-${styleSample.styleName}`
                            ? "PAUSE SAMPLE"
                            : "PLAY SAMPLE"}
                          <img
                            src={images.PlayAudio}
                            style={{ marginLeft: "8px" }}
                            alt="play audio"
                          />
                        </button>

                        <button className="play-sample-button select-button mt-2">
                          SELECT
                        </button>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <div className="audioSpeed_box">
                <div className="audio-speed-section">
                  <label className="audio-label">
                    Audio Speed: <b style={{ color: "red" }}> *</b>
                  </label>
                  <div className="audio-speed-options">
                    <label className="custom-check form-label">
                      100 WPM
                      <input
                        type="radio"
                        name="speed"
                        value="0.8"
                        onChange={() => handleSpeedChange(0.8, 100)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-check form-label">
                      120 WPM
                      <input
                        type="radio"
                        name="speed"
                        value="1.0"
                        onChange={() => handleSpeedChange(1.0, 120)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-check form-label">
                      140 WPM
                      <input
                        type="radio"
                        name="speed"
                        value="1.2"
                        onChange={() => handleSpeedChange(1.2, 140)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-check form-label">
                      160 WPM
                      <input
                        type="radio"
                        name="speed"
                        value="1.3"
                        onChange={() => handleSpeedChange(1.3, 160)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-check form-label">
                      180 WPM
                      <input
                        type="radio"
                        name="speed"
                        value="1.4"
                        onChange={() => handleSpeedChange(1.4, 180)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-check form-label">
                      200 WPM
                      <input
                        type="radio"
                        name="speed"
                        value="1.5"
                        onChange={() => handleSpeedChange(1.5, 200)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>

                <div className="btn-list">
                  <div className="noDecoration">
                    <button className="small" onClick={() => setStep(1)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        fill="currentColor"
                      >
                        <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                      </svg>
                      BACK
                    </button>
                  </div>
                  <div className="noDecoration">
                    <button className="small" onClick={GenerateAudio}>
                      GENERATE AUDIO
                      <svg
                        xmlns="http:www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        fill="currentColor"
                      >
                        <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
      {showErorModal && (
        <ErrorModalComponent
          modalText="Please try again with Another Audio Language Style."
          onClose={closeModal}
          onClickTryAgain={GenerateAudio}
        />
      )}
      <OpsModal
        show={showOpsModal}
        onNextStep={nextStep}
        onClose={closeModal}
        model={"Audio"}
      />
      {showConfirmationModal && (
        <ConfirmationModal
          onConfirm={handleConfirmSelection}
          onCancel={handleCancelSelection}
        />
      )}
    </div>
  );
};

export default VoiceCatalog;
