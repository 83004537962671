import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AudioVisualizer, LiveAudioVisualizer } from "react-audio-visualize";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import "./AudioRecorder.css";
import images from "../../images";
import logo from "../../assets/Images/logo-big.jpg";

import HeaderWhiteComponent from "../../components/HeaderWhite/HeaderWhite";
import AudioSaveModal from "../../components/Modal/AudioSave/AudioSave";
import EvaluateModal from "../../components/Modal/EvaluateModel/EvaluateModal";
import AudioCancelModal from "../../components/Modal/AudioCancle/AudioCancle";
import { fetchTranscriptService } from "../../services/transcript.service";
import { submitRecordingService } from "../../services/audio.service";
import { UserContext } from "../../providers/UserContext";
import WaveSurfer from "wavesurfer.js";
import { API_URL } from "../../constants";
import TimerSpiner from "../../components/Timer/Timer";
import Overlay from "../../components/Timer/Overlay/Overlay";
import AudioPlayModal from "../../components/Modal/AudioPlay/AudioPlay";
import GlowingCountdown from "../../components/GlowingCountdown/GlowingCountdown";
import MicrophoneChecker from "../../components/MicrophoneChecker/MicrophoneChecker";
import { toast } from "react-toastify";

function AudioRecordPlayer() {
  const { id } = useParams();
  const [transcriptText, setTranscriptText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPlayOpen, setIsModalPlayOpen] = useState(false);

  const [isEvaluateModalOpen, setIsEvaluateModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [audioFile, setAudioFile] = useState();
  const [countdown, setCountdown] = useState(0); // For countdown
  const [firstPlay, setFirstPlay] = useState(true); // To track the first play
  const [playing, setPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [isPaused, setIsPaused] = useState(false);
  const [loading, setLoading] = useState(true);
  const [blob, setBlob] = useState();

  const { currentUser } = useContext(UserContext);
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const countdownInterval = useRef(null);
  // const recorder = useAudioRecorder();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTranscriptText = async () => {
      setLoading(true);
      try {
        const transcriptData = await fetchTranscriptService(
          id,
          currentUser.access
        );
        if (transcriptData) {
          const audios = transcriptData.save_audios || [];
          const audio = audios.find((item) => item.audio_file_path != null);
          if (audio) {
            const url = `${API_URL}/media/${audio.audio_file_path}`;
            setAudioUrl(url);
            setIsModalPlayOpen(true);
            console.log("Audio URL set:", url);
          } else {
            console.error("No valid audio file found");
          }
        }
      } catch (error) {
        console.error("Error fetching the transcript:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTranscriptText();
  }, [id, currentUser]);

  useEffect(() => {
    if (audioUrl) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "#ccc",
        progressColor: "#007bff",
        height: 80,
        barWidth: 1,
        responsive: false,
        barGap: 1,
        minPxPerSec: 10,
        normalize: true,
        // autoScroll:false
        // hideScrollbar:true,
      });

      wavesurfer.current.load(audioUrl);

      wavesurfer.current.on("ready", () => {
        setLoading(false);
        if (playing) {
          wavesurfer.current.play();
        }
      });

      wavesurfer.current.on("error", (e) => {
        console.error("Error loading audio:", e);
        setLoading(false);
      });

      wavesurfer.current.on("finish", () => {
        setPlaying(false);
        clearInterval(countdownInterval.current); // Stop the countdown when audio finishes
        setCountdown(0);
      });

      return () => {
        wavesurfer.current.destroy();
        clearInterval(countdownInterval.current); // Cleanup countdown
      };
    }
  }, [audioUrl]);

  const handlePlayPause = () => {
    if (wavesurfer.current) {
      if (firstPlay) {
        setIsModalPlayOpen(true);
        setFirstPlay(false);
      } else {
        if (wavesurfer.current) {
          // Toggle play/pause for subsequent clicks
          wavesurfer.current.playPause();
        }
        setPlaying(!playing);
        startRecording();
        console.log("Playing state changed:", !playing); // Log new state
      }
    }
  };
  const onStart = () => {
    setIsModalPlayOpen(false);
    const initialCountdown = 5; // Countdown duration in seconds
    setCountdown(initialCountdown);

    countdownInterval.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownInterval.current);
          if (wavesurfer.current) {
            wavesurfer.current.play(); // Start playing the audio after countdown
          }

          setPlaying(true);
          startRecording();
          setFirstPlay(false); // Reset firstPlay to avoid future countdowns
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const handleStop = () => {
    if (wavesurfer.current) {
      wavesurfer.current.stop();
      setPlaying(false);
      clearInterval(countdownInterval.current);
      setCountdown(0); // Reset the countdown when stopped
    }
  };

  const handleTogglePauseResume = () => {
    if (wavesurfer.current) {
      wavesurfer.current.playPause(); // This pauses/resumes the audio
    }
    togglePauseResume(); // This pauses/resumes the recording
    setIsPaused(!isPaused); // Track pause state
  };

  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    mediaRecorder,
  } = useAudioRecorder();

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isRecording && wavesurfer.current) {
      // wavesurfesr.current.playPause();
      // setPlaying(!playing);
    }
  }, [isRecording]);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    const fetchTranscriptText = async () => {
      try {
        const response = await fetchTranscriptService(id, token);
        if (response) {
          setTranscriptText(response.transcript.transcript);
        }
      } catch (error) {
        console.error("Error fetching the transcript:", error);
      }
    };

    if (id) {
      fetchTranscriptText();
    }
  }, [id]);

  const transcriptArray = Array.isArray(transcriptText)
    ? transcriptText
    : typeof transcriptText === "string"
    ? transcriptText.split("\n").filter(Boolean)
    : [];

  const formattedTranscript = transcriptArray
    .map((paragraph) => paragraph.replace("<break/>/g", "\n \n"))
    .join("\n\n");

  // Utility function to determine the file extension based on the MIME type
  const getFileExtension = (mimeType) => {
    switch (mimeType) {
      case "audio/webm":
        return "webm";
      case "audio/mp3":
        return "mp3";
      case "audio/wav":
        return "wav";
      default:
        return "audio"; // fallback in case of an unrecognized MIME type
    }
  };

  useEffect(() => {
    if (!recordingBlob) return;
    // console.log("webmFileURL", webmFileURL);
    const extension = getFileExtension(recordingBlob.type); // Get the extension based on MIME type
    const fileName = `${id}_audioRecording.${extension}`; // Use the dynamic extension in file name
    const audioFile = blobToFile(recordingBlob, fileName);
    // console.log("Audio File:", audioFile);
    setAudioFile(audioFile);
    setIsModalOpen(true);
    // Reset the audio playback to the start after saving the recording
    if (wavesurfer.current) {
      wavesurfer.current.stop(); // Stop and reset playback
      wavesurfer.current.seekTo(0); // Set audio playback position to the start
      setPlaying(false); // Reset the play state
    }
  }, [recordingBlob, id]);

  useEffect(() => {
    if (!blob) return;

    const extension = getFileExtension(blob.type); // Get the extension based on MIME type
    const fileName = `${id}_audioRecording.${extension}`; // Use the dynamic extension in file name
    const audioFile = blobToFile(blob, fileName);
    console.log("Audio File:", audioFile);
    setAudioFile(audioFile);
    setIsModalOpen(true);
    // Reset the audio playback to the start after saving the recording
    if (wavesurfer.current) {
      wavesurfer.current.stop(); // Stop and reset playback
      wavesurfer.current.seekTo(0); // Set audio playback position to the start
      setPlaying(false); // Reset the play state
    }
  }, [blob, id]);

  const blobToFile = (blob, fileName) => {
    console.log("blob", blob);
    return new File([blob], fileName, { type: blob.type });
  };

  const submitAudio = async (data) => {
    const email = currentUser.email;
    const accessToken = currentUser.access;

    const response = await submitRecordingService(
      id,
      audioFile,
      data.audioName,
      data.removeSilence,
      email,
      accessToken
    );

    setIsModalOpen(false);
    setIsEvaluateModalOpen(true);

    // Reset the audio playback to the start after saving the recording
    if (wavesurfer.current) {
      wavesurfer.current.stop(); // Stop and reset playback
      wavesurfer.current.seekTo(0); // Set audio playback position to the start
      setPlaying(false); // Reset the play state
    }
  };

  const goToRecordingWithKeyword = () => {
    navigate(`/recording-with-keyword/${id}`);
  };

  const evaluateModalClose = () => {
    toast.success("Your recording has been successfully saved.");
    navigate("/practice-dashboard");
    setIsEvaluateModalOpen(false);
  };
  const handleCancelModalOpen = () => {
    setIsCancelModalOpen(true);
  };

  const handleCancelModalClose = () => {
    setIsCancelModalOpen(false);
  };

  const handleCancelConfirm = () => {
    setIsCancelModalOpen(false);
  };
  const backClick = () => {
    if (wavesurfer.current && wavesurfer.current.isPlaying()) {
      wavesurfer.current.stop(); // Ensure the audio is fully stopped
      setPlaying(false); // Reset the play state
    }

    navigate(-1); // Navigate back
  };

  return (
    <div className="player-container">
      <HeaderWhiteComponent className="headerr" />

      <div className="mainContentBox">
        <div className="topBarcontentRight-player">
          <div className="userName">
            <button className="btn btn-primary" onClick={backClick}>
              Back
            </button>
          </div>
        </div>
        <div className="record-player">
          {loading ? (
            <div className="loader"></div>
          ) : (
            <div className="player-player-content">
              <div className="icon-container">
                <img
                  src={logo}
                  alt="logo"
                  className="logoImg"
                  style={{ width: "150px" }}
                />
              </div>
              <div className="waveform-container">
                {/* {playing ? */}
                <div ref={waveformRef} className="waveform"></div>
                {/* :
                  <img
                  src={images.audioWave}
                  alt="audio Wave"
                />
                } */}
              </div>
              <AudioPlayModal
                isOpen={isModalPlayOpen}
                onClose={() => setIsModalPlayOpen(false)}
                onStart={onStart}
              />
              <Overlay isVisible={countdown > 0}>
                <GlowingCountdown />
              </Overlay>
              {/* <div className="controls">
                <button
                  disabled={playing && !firstPlay} // Disable Play if already playing and not first play
                  className="control-button interpret-control"
                  onClick={handlePlayPause}
                >
                  Play
                </button>
                <button
                  disabled={!playing}
                  className="control-button interpret-control"
                  onClick={handlePlayPause}
                >
                  Pause
                </button>
                <button
                  disabled={!playing}
                  className="control-button interpret-control"
                  onClick={handleStop}
                >
                  Stop
                </button>
              </div> */}
            </div>
          )}
        </div>
        {/* <div className="player-content">
          <div className="transcript-recorder-container">
            <p>{formattedTranscript}</p>
          </div>
        </div> */}
        {countdown === 0 && (
          <div className="footer-audio-recording">
            <div className="audio-recorder-controller">
              {mediaRecorder && (
                <LiveAudioVisualizer
                  mediaRecorder={mediaRecorder}
                  width={300}
                  height={60}
                />
              )}
              {/* <AudioRecorder
                onRecordingComplete={setBlob}
                recorderControls={recorder}

              /> */}
              {/* <button onClick={recorder.stopRecording}>Stop recording</button> */}
            </div>

          <div className="audio-recording-player">
            {!isRecording ? (
               isModalPlayOpen ? 
               <MicrophoneChecker />
               : ""
              //   (<div className="record-container" onClick={handlePlayPause}>
              //   <img
              //     src={images.mike}
              //     alt="Microphone"
              //     className="microphone-icon"
              //   />
              //   <p className="record-text">Tap to Record and Play Audio</p>
              // </div>)
              
              
            ) : (
              <div className="recording-controls">
                {/* <div className="">

                  <p className="recordingTime">
                    {isRecording
                      ? isPaused
                        ? "Recording paused..."
                        : "Recording..."
                      : ""}
                  </p>
                </div> */}

                  <div className="audioControlbtn">
                    <button className="control-btn" onClick={stopRecording}>
                      <img src={images.stop} alt="Stop and Save" />
                      <p>Stop & Save</p>
                    </button>

                    <button
                      className="control-btn"
                      onClick={handleTogglePauseResume}
                    >
                      <img
                        src={images.pause}
                        alt={isPaused ? "Resume" : "Pause"}
                      />
                      <p>{isPaused ? "Resume" : "Pause"}</p>

                      {isRecording ? (
                        isPaused ? (
                          ""
                        ) : (
                          <div id="record-on" className="blink-recording"></div>
                        )
                      ) : (
                        ""
                      )}
                    </button>

                    <button
                      className="control-btn"
                      onClick={handleCancelModalOpen}
                    >
                      <img src={images.cancle} alt="Cancel" />
                      <p>Cancel</p>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <AudioSaveModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSave={(data) => submitAudio(data)}
      />

      <EvaluateModal
        isOpen={isEvaluateModalOpen}
        onClose={evaluateModalClose}
        onNowClick={goToRecordingWithKeyword}
      />
      <AudioCancelModal
        show={isCancelModalOpen}
        handleClose={handleCancelModalClose}
        handleConfirm={handleCancelConfirm}
      />
    </div>
  );
}

export default AudioRecordPlayer;
