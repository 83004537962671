import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./Timer.css";

const TimerSpiner = () => {
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    let timer;
    if (seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [seconds]);

  return (
    <div className="timer-spinner">
      <CircularProgressbar
        value={seconds}
        maxValue={5}
        text={`${seconds}s`}
        styles={buildStyles({
          textSize: "26px",
          pathColor: getGradientColor(seconds),
          textColor: "#fff",
          trailColor: "#1a1a1a",
          strokeLinecap: "round",
          strokeWidth: 10,
        })}
      />
    </div>
  );
};

const getGradientColor = (seconds) => {
  const colors = [
    // "#1A5FB",

    "#43A047", // End: green
    // "#000",
  ];
  const index = Math.floor((seconds / 5) * (colors.length - 1));
  return colors[index];
};

export default TimerSpiner;
