import React, { useEffect, useState, useRef, useContext } from "react";
import "./VoiceGenerate.css";
import HeaderComponent from "components/Header/Header";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { REACT_APP_GET_TRANSCRIPT_API, API_URL } from "../../../constants";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer/Footer";
import OpsModal from "../../../components/Modal/OppsModal/OpsModal";
import TranscriptSection from "../../../components/TranscriptComponent/TranscriptComponent";
import AudioPlayer from "../../../components/AudioPlayer/AudioPlayer";
import { submitAudioService } from "../../../services/audio.service";
import { fetchTranscriptService } from "../../../services/transcript.service";
import axios from "axios";
import { UserContext } from "../../../providers/UserContext";

const TranscriptVoice = () => {
  const { currentUser } = useContext(UserContext);
  const [usertoken] = useState(currentUser.access || "");
  const [toggles, setToggles] = useState([]);
  const [transcript, setTranscript] = useState([]);
  const [topic, setTopic] = useState();
  const [audios, setAudios] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const audioRefs = useRef([]);
  const { id } = useParams();

  const {
    voice = "default-voice",
    speed = "default-speed",
    style_name = "default-style",
    wpm = "default-wpm",
  } = location.state || {};

  useEffect(() => {
    if (currentUser && currentUser.access) {
      fetchTranscriptText();
    }
  }, [id, currentUser]);

  const fetchTranscriptText = async () => {
    try {
      const response = await axios.post(
        REACT_APP_GET_TRANSCRIPT_API,
        {
          identifier: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentUser.access}`,
          },
        }
      );

      if (response.data && response.data.transcripts[0]) {
        setTranscript(response.data.transcripts[0]);
        setTopic(response.data.keyword);

        // Reverse the audios array to have the latest audio first
        const reversedAudios = [...response.data.temporary_audios].reverse();
        setAudios(reversedAudios);

        // Initialize toggles: first toggle true, rest false
        if (reversedAudios.length > 0) {
          setToggles(
            reversedAudios.map((_, index) => (index === 0 ? true : false))
          );
        } else {
          setToggles([]);
        }
      } else {
        setTranscript("Transcript not found.");
        setAudios([]);
        setToggles([]);
      }
    } catch (error) {
      console.error("Error fetching the transcript:", error);
      toast.error("Failed to fetch the transcript.");
      setTranscript("Error fetching transcript.");
      setAudios([]);
      setToggles([]);
    }
  };

  const handleToggleChange = (index) => {
    const newToggles = toggles.map((toggle, i) =>
      i === index ? !toggle : false
    );
    setToggles(newToggles);
  };

  const submitAudio = async () => {
    const selectedIndex = toggles.findIndex((toggle) => toggle);
    if (selectedIndex === -1) {
      toast.error("Please select an audio to submit.");
      return;
    }

    const selectedAudio = audios[selectedIndex];
    const selectedAudioUrl = `${API_URL}${selectedAudio.audio_file}`;
    const audioFileName = selectedAudioUrl.replace(`${API_URL}/media/`, "");

    try {
      const dataToSend = {
        identifier: id,
        audio_files: [audioFileName], // Assuming you need an array
        voice: voice,
        speed: speed,
        style_name: style_name,
        wpm: wpm,
      };
      const is_complete = topic?.is_complete === true;

      const data = await submitAudioService(dataToSend, is_complete);

      if (data.status === 200) {
        toast.success(
          "Congratulations! Your practice was successfully generated."
        );
        navigate(`/voice-congratulation/${id}`);
      } else {
        toast.error("Error submitting audio.");
      }
    } catch (error) {
      console.error("Error submitting the audio:", error);
      toast.error("Error submitting the audio.");
    }
  };

  const regenerateAudio = async () => {
    if (audios.length === 0) {
      toast.error("No audios available for regeneration.");
      return;
    }

    if (audios.length < 3) {
      navigate(`/voice-catalog/${id}`);
    } else if (audios.length === 3) {
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="transcript-container">
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="main-content">
          <h1 className="transcript-title">Generated Audio</h1>
          {/* <p className="transcript-para-p">
            Congratulations! Your audio has been successfully created. Let's
            move forward to complete the practice generation.
          </p> */}
          {audios && audios.length > 1 ? (
            <div className="audio-generate-text">
              <p className="transcript-sugetion-text">
                <span>*</span>
                {/* Here is the list of all generated audios. The
                latest one is selected green by default. If you wish to change
                the audio for the transcript, <br /> Please use the toggle to
                select a different audio. */}
                Here’s a list of all generated audios. The latest one is
                highlighted in green by default. To change the audio for the
                transcript, simply use the toggle to select a different one.
              </p>
            </div>
          ) : (
            <p className="transcript-para-p">
              Congratulations! Your audio has been successfully created. Let's
              move forward to complete the practice generation.
            </p>
          )}
          {/* <TranscriptSection
            transcriptText={
              transcript ? transcript.transcript : "Transcript not found"
            }
          /> */}

          <div className="audio-section">
            {/* <h2 className="audio-title">Generated Audio</h2> */}

            {audios && audios.length > 0 ? (
              audios.map((audioData, index) => (
                <div className="audio-section-player-div" key={index}>
                  <AudioPlayer
                    index={index}
                    toggles={toggles}
                    audioData={audioData}
                    API_URL={API_URL}
                    audioRefs={audioRefs}
                    src={`${API_URL}${audioData.audio_file}`}
                    playStatus={(e) => console.log(e)}
                  />
                  {audios.length > 1 && (
                    <div className="toggle-container">
                      <div className="toggle-switch">
                        <input
                          className="toggle-input"
                          id={`toggle-${index}`}
                          type="checkbox"
                          onChange={() => handleToggleChange(index)}
                          checked={toggles[index]}
                        />
                        <label
                          className="toggle-label"
                          htmlFor={`toggle-${index}`}
                        ></label>
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>Audio not found</p>
            )}
          </div>

          <div className="btn-list">
            <div className="noDecoration mb-3">
              <button className="small" onClick={regenerateAudio}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                </svg>
                REGENERATE
              </button>
            </div>
            <div className="noDecoration mb-3">
              <button className="small-continue" onClick={submitAudio}>
                CONTINUE WITH PRACTICE GENERATION
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <OpsModal show={showModal} onClose={closeModal} model={"Audio"} />
    </div>
  );
};

export default TranscriptVoice;
