import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Swiper.css";
import images from "../../images";
import { useNavigate } from "react-router";

const SwiperComponent = () => {
  return (
    <div className="container">
      <Swiper
        className="swiper"
        modules={[]}
        // loop={true}
        // autoplay={{ delay: 3000 }}
        // pagination={{ clickable: true }}
        // navigation={{
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // }}
        breakpoints={{
          320: { slidesPerView: 1, spaceBetween: 20 },
          640: { slidesPerView: 2, spaceBetween: 20 },
          768: { slidesPerView: 3, spaceBetween: 30 },
        }}
      >
        <SwiperSlide className="swiper-slide">
          <div className="card">
            <div className="card-body">
              <img
                className="card-img-top"
                src={images.slider1}
                alt="slider1"
              />
              <h5 className="card-title">
                Step 1 : Vocabulary Flashcards Setup
              </h5>
              {/* <p className="card-text">
                Customize your vocabulary flashcards by specifying the number of
                terms, language combination, topic, difficulty level, and any
                special requirements.
              </p> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          <div className="card">
            <div className="card-body">
              <img
                className="card-img-top"
                src={images.slider2}
                alt="slider2"
              />
              <h5 className="card-title">Step 2 : Transcript Creation</h5>
              {/* <p className="card-text">
                Define the length, speaker, context, and any special language
                requirements for a transcript that will include your selected
                vocabulary terms.
              </p> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          <div className="card">
            <div className="card-body">
              <img
                className="card-img-top"
                src={images.slider3}
                alt="slider3"
              />
              <h5 className="card-title">Step 3 : Audio Practice Setup</h5>
              {/* <p className="card-text">
                Select the voice for the audio practice to enhance your
                listening and interpreting skills with realistic scenarios.
              </p> */}
            </div>
          </div>
        </SwiperSlide>

        {/* <SwiperSlide className="swiper-slide">
          <div className="card">
            <div className="card-body">
              <img
                className="card-img-top"
                src={images.slider1}
                alt="slider1"
              />
              <h5 className="card-title">
                Step 1 : Vocabulary Flashcards Setup
              </h5>
              {/* <p className="card-text">
                Customize your vocabulary flashcards by specifying the number of
                terms, language combination, topic, difficulty level, and any
                special requirements.
              </p> */}
        {/* </div>
          </div>
        </SwiperSlide> */}
        {/* <SwiperSlide className="swiper-slide">
          <div className="card">
            <div className="card-body">
              <img
                className="card-img-top"
                src={images.slider2}
                alt="slider2"
              />
              <h5 className="card-title">Step 2 : Transcript Creation</h5> */}
        {/* <p className="card-text">
                Define the length, speaker, context, and any special language
                requirements for a transcript that will include your selected
                vocabulary terms.
              </p> */}
        {/* </div>
          </div>
        </SwiperSlide> */}
        {/* <SwiperSlide className="swiper-slide">
          <div className="card">
            <div className="card-body">
              <img
                className="card-img-top"
                src={images.slider3}
                alt="slider3"
              />
              <h5 className="card-title">Step 3 : Audio Practice Setup</h5>
              {/* <p className="card-text">
                Select the voice for the audio practice to enhance your
                listening and interpreting skills with realistic scenarios.
              </p> */}
        {/* </div>
          </div>
        </SwiperSlide> */}
        {/* <SwiperSlide className="swiper-slide">
          <div className="card">
            <div className="card-body">
              <img
                className="card-img-top"
                src={images.slider3}
                alt="slider3"
              />
              <h5 className="card-title">Step 3 : Audio Practice Setup</h5>
              <p className="card-text">
                Select the voice for the audio practice to enhance your
                listening and interpreting skills with realistic scenarios.
              </p>
            </div>
          </div>
        </SwiperSlide> */}
        {/* <SwiperSlide className="swiper-slide">
          <div className="card">
            <div className="card-body">
              <img
                className="card-img-top"
                src={images.slider3}
                alt="slider3"
              />
              <h5 className="card-title">Step 3 : Audio Practice Setup</h5>
              <p className="card-text">
                Select the voice for the audio practice to enhance your
                listening and interpreting skills with realistic scenarios.
              </p>
            </div>
          </div>
        </SwiperSlide> */}

        {/* <div className="swiper-pagination"></div> */}
      </Swiper>
    </div>
  );
};

export default SwiperComponent;
