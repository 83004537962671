import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setTranscriptText,
  addTranscriptToHistory,
  navigateTranscriptHistory,
  editCurrentTranscript,
  clearTranscriptHistory,
} from "../../../redux/vocabSlice";
import Loader from "../../../components/Loader/Loader";
import HeaderComponent from "components/Header/Header";
import { FormContext } from "../../../providers/FormContext";
import { useNavigate, useParams } from "react-router";
import images from "../../../images";
import Footer from "../../../components/Footer/Footer";

import OpsModal from "../../../components/Modal/OppsModal/OpsModal";
import {
  fetchTranscriptService,
  saveTranscript,
  submitTranscriptData,
} from "../../../services/transcript.service";
import { toast } from "react-toastify";
import { UserContext } from "../../../providers/UserContext";
import ErrorModalComponent from "../../../components/Modal/TryAgainModal/TryAgainModal";
import { cleanTranscript } from "../../../utils/transcriptUtils";
import TranscriptTextHeighlight from "./components/TranscriptText";
import RegenerateTranscript from "../../../components/Modal/RegenerateTranscript/RegenerateTranscript";
function TranscriptGenerated() {
  const [isEditing, setIsEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showRegenerateTranscriptModal, setShowRegenerateTranscriptModal] =
    useState(false);
  const { currentUser } = useContext(UserContext);
  const [usertoken] = useState(currentUser.access || "");
  const { formData } = useContext(FormContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const identifier = id;
  const [loading, setLoading] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showErorModal, setShowErorModal] = useState(false);

  const transcriptText = useSelector((state) => state.vocab.transcriptText);
  const transcriptHistory = useSelector(
    (state) => state.vocab.transcriptHistory
  );
  const currentTranscriptIndex = useSelector(
    (state) => state.vocab.currentTranscriptIndex
  );
  const dispatch = useDispatch();
  const transcriptRef = useRef(null);
  const [keywords, setKeywords] = useState([]);

  const [originalTranscript, setOriginalTranscript] = useState("");

  useEffect(() => {
    console.log("Transcript History:", transcriptHistory);
    // if(transcriptHistory.length === 0){
    //    toast.success("something want wrong..");
    //   navigate(`/practice-dashboard`);
    // }
  }, [transcriptHistory, currentUser]);
  useEffect(() => {
    // Push a new entry to the history stack
    window.history.pushState(null, document.title, window.location.href);

    // Function to handle popstate event
    const handlePopState = (event) => {
      // Prevent default behavior (go back)
      window.history.pushState(null, document.title, window.location.href);
    };

    // Listen for popstate events
    window.addEventListener("popstate", handlePopState);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  useEffect(() => {
    // const token = localStorage.getItem("accessToken");

    const fetchTranscriptText = async () => {
      try {
        const data = await fetchTranscriptService(id, currentUser.access);
        if (data) {
          setKeywords(data.keyword.keywords_list);
        }
      } catch (error) {
        console.error("Error fetching the transcript:", error);
      }
    };

    if (id && currentUser && currentUser.access) {
      fetchTranscriptText();
    }
  }, [id, currentUser]);

  const handleEditClick = () => {
    setIsEditing(true);

    setOriginalTranscript(transcriptText);
  };

  const handleSaveClick = () => {
    setIsEditing(false);

    dispatch(editCurrentTranscript({ newTranscript: transcriptText }));
  };

  const handlePrevClick = () => {
    dispatch(navigateTranscriptHistory({ direction: "prev" }));
  };

  const handleNextClick = () => {
    dispatch(navigateTranscriptHistory({ direction: "next" }));
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    dispatch(setTranscriptText(originalTranscript));
  };

  const handleChange = (e) => {
    const updatedText = e.target.value;
    dispatch(setTranscriptText(updatedText.replace(/\n/g, "<br/>")));
  };

  const saveTranscriptClick = async () => {
    try {
      setLoading(true);
      // const accessToken = localStorage.getItem("accessToken");

      if (!currentUser.access || !identifier) {
        navigate("/login");
        console.error("Missing access token or practice identifier.");
        return;
      }

      let updateText = Array.isArray(transcriptText)
        ? transcriptText.join(" ")
        : transcriptText;
      // updateText = updateText.replace(/\n\s*\n/g, "<break/>");
      updateText = updateText.replace(/<br\/>\s*<br\/>/g, "<break/>");
      const dataToSend = {
        identifier: identifier,
        transcript: updateText,
        transcript_length: formData.transcript_length,
        additional_instructions: formData.additional_info,
        conversation_type:formData.conversationType,
        speaker: formData.speaker_info,
        person1: formData.person1,
        person2: formData.person2,
      };
      console.log(dataToSend, "dataToSend");

      const response = await saveTranscript(
        currentUser.access,
        identifier,
        dataToSend
      );

      // console.log("Transcript saved successfully:", response);
      toast.success("Transcript saved successfully");
      navigate(`/voice-catalog/${id}`, {
        state: { transcript: transcriptText },
      });
      dispatch(clearTranscriptHistory());
    } catch (error) {
      console.error("Error saving transcript:", error);
    } finally {
      setLoading(false);
    }
  };
  // const saveTranscriptClick = async () => {
  //   try {
  //     setLoading(true);
  //     const accessToken = localStorage.getItem("accessToken");
  //     // const identifier = localStorage.getItem("practiceIdentifier");

  //     if (!accessToken || !identifier) {
  //       console.error("Missing access token or practice identifier.");
  //       return;
  //     }

  //     const dataToSend = {
  //       identifier: identifier,
  //       transcript: Array.isArray(transcriptText)
  //         ? transcriptText[0]
  //         : transcriptText,
  //       transcript_length: formData.transcript_length,
  //       additional_instructions: formData.additional_info,
  //       speaker: formData.speaker_info,
  //     };
  //     console.log(dataToSend, "dataToSend");
  //     const response = await fetch(REACT_APP_SAVE_TRANSCRIPT_API, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(dataToSend),
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       console.log("Transcript saved successfully:", data);

  //       navigate(`/voice-catalog/${id.id}`, {
  //         state: { transcript: transcriptText },
  //       });
  //       dispatch(clearTranscriptHistory());
  //     } else {
  //       const errorData = await response.json();
  //       console.error("Failed to save transcript:", errorData);
  //     }
  //   } catch (error) {
  //     console.error("Error saving transcript:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const regenerateTranscriptModal = () => {
    setShowRegenerateTranscriptModal(true);
  };

  const regenerateTranscript = async (feedbacks) => {
    // e.preventDefault();
    setShowRegenerateTranscriptModal(false);
    setShowErorModal(false);
    // const accessToken = localStorage.getItem("accessToken");

    if (!currentUser.access) {
      navigate("/login");

      return;
    }
    const maxRetries = 2;
    let attempt = 0;
    let success = false;

    if (transcriptHistory.length > 2) {
      setShowModal(true);
      return;
    }

    while (attempt <= maxRetries && !success) {
      try {
        setLoading(true);

        const dataToSend = {
          identifier: identifier,
          transcript_length: formData.transcript_length,
          additional_instructions: formData.additional_info,
          conversation_type:formData.conversationType,
          speaker: formData.speaker_info,
          person1: formData.person1,
          person2: formData.person2,
          feedbacks: feedbacks,
        };

        const response = await submitTranscriptData(
          dataToSend,
          currentUser.access,
          maxRetries
        );

        const newTranscript = response.transcript;

        if (
          !transcriptHistory.some(
            (entry) => entry.transcript === transcriptText
          )
        ) {
          dispatch(
            addTranscriptToHistory({
              transcript: transcriptText,
              identifier: identifier,
            })
          );
        }
        toast.success("Transcript Generated");
        // Update the transcript with the newly generated one
        dispatch(setTranscriptText(newTranscript));
        dispatch(
          addTranscriptToHistory({
            transcript: newTranscript,
            identifier: identifier,
          })
        );
        handleScrollToTop();
        success = true;
        break;
      } catch (error) {
        if (attempt >= maxRetries) {
          console.error(
            "Error regenerating transcript after multiple attempts:",
            error
          );
          toast.error(
            "Right now I am unable to regenerate the transcript. Please try again later."
          );
        } else {
          console.error("Error regenerating transcript, retrying...", error);
        }
      } finally {
        attempt += 1;
        setLoading(false);
      }
    }
  };

  // Scroll to top on button click
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional smooth scroll
    });
  };

  const transcriptArray = Array.isArray(transcriptText)
    ? transcriptText
    : typeof transcriptText === "string"
    ? transcriptText.split("\n").filter(Boolean)
    : [];

  // const formattedTranscript = transcriptArray.map((paragraph) =>
  //   paragraph.replace(/<break \/>/g, "\n \n")
  // );

  const formattedTranscript = cleanTranscript(transcriptArray);

  // if(formattedTranscript.length === 0){
  //   toast.success("something want wrong..");
  //     navigate(`/practice-dashboard`);
  // }

  const closeModal = () => {
    setShowErorModal(false);
    setShowModal(false);
  };
  // Function to enter fullscreen
  const enterFullScreen = () => {
    if (transcriptRef.current.requestFullscreen) {
      transcriptRef.current.requestFullscreen();
    } else if (transcriptRef.current.mozRequestFullScreen) {
      transcriptRef.current.mozRequestFullScreen(); // Firefox
    } else if (transcriptRef.current.webkitRequestFullscreen) {
      transcriptRef.current.webkitRequestFullscreen(); // Chrome, Safari and Opera
    } else if (transcriptRef.current.msRequestFullscreen) {
      transcriptRef.current.msRequestFullscreen(); // IE/Edge
    }

    setIsFullScreen(!isFullScreen);
  };

  // Function to exit fullscreen
  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen(); // Firefox
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen(); // Chrome, Safari and Opera
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen(); // IE/Edge
    }

    setIsFullScreen(!isFullScreen);
  };
  const textareaRef = useRef(null); // Ref to target textarea

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.focus(); // Set focus to textarea

      // Move the cursor to the end of the text
      const end = textarea.value.length;
      textarea.setSelectionRange(end, end);

      // Scroll to the bottom to make sure cursor is visible
      textarea.scrollTop = textarea.scrollHeight;
    }
  }, [isEditing]);
  return (
    <div className="min-screen Transcript">
      <HeaderComponent />
      <div className="tarnscript-mainContentBox">
        <div className="trancript-container">
          <h1 className="title">Transcript Generation</h1>

          <div
            className={`trancsript-scollbar ${
              isFullScreen ? "fullScreenBox" : ""
            }`}
            ref={transcriptRef}
          >
            {/* <div className="text-right">
              {isFullScreen ? (
                <button onClick={exitFullScreen} className="fullscreen-button">
                  Exit Fullscreen
                </button>
              ) : (
                <button onClick={enterFullScreen} className="fullscreen-button">
                  View Fullscreen
                </button>
              )}
            </div> */}
            {/* {formattedTranscript && keywords && (
              <textarea
                className="text scrollbar"
                readOnly={!isEditing}
                keywords={keywords}
                value={formattedTranscript}
                onChange={handleChange}
              />
            )} */}
            {formattedTranscript &&
              keywords &&
              (isEditing ? (
                <div className="card mb-4">
                  <div
                    className="card-header"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img className="my-2" src={images.editpage}></img>
                    <h4 className="my-2 edit-transcript">Edit Transcript</h4>
                  </div>

                  <div className="card-body">
                    <textarea
                      ref={textareaRef}
                      className="text scrollbar m-0 textarea-transcript"
                      readOnly={!isEditing}
                      keywords={keywords}
                      // value={formattedTranscript}
                      value={formattedTranscript.replace(
                        /<\/?br\s*\/?>/g,
                        "\n"
                      )}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="text-right">
                    {isFullScreen ? (
                      <button
                        onClick={exitFullScreen}
                        className="fullscreen-button"
                      >
                        Exit Fullscreen
                      </button>
                    ) : (
                      <button
                        onClick={enterFullScreen}
                        className="fullscreen-button"
                      >
                        View Fullscreen
                      </button>
                    )}
                  </div>
                  <TranscriptTextHeighlight
                    className="text scrollbar"
                    text={formattedTranscript}
                    keywords={keywords}
                    readOnly={!isEditing}
                    onChange={handleChange}
                  />
                </>
              ))}
          </div>

          <div className="btn-list">
            {isEditing ? (
              <>
                <button
                  onClick={handleSaveClick}
                  className="btn btn-light edit"
                >
                  SAVE
                </button>
                <button
                  onClick={handleCancelClick}
                  className="btn btn-light edit"
                >
                  CANCEL
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={handleEditClick}
                  className="btn btn-light edit"
                >
                  EDIT
                </button>
                <div className="prenext-div">
                  {transcriptHistory.length > 1 && (
                    <button
                      className="btn btn-secondary roundbtn"
                      onClick={handlePrevClick}
                      disabled={currentTranscriptIndex <= 0}
                    >
                      <img src={images.ArrowLeft} alt="ArrowLeft" />
                    </button>
                  )}

                  <button
                    onClick={regenerateTranscriptModal}
                    className="btn btn-light regenerate"
                  >
                    REGENERATE
                  </button>

                  {transcriptHistory.length > 1 && (
                    <button
                      className="btn btn-secondary roundbtn"
                      onClick={handleNextClick}
                      disabled={
                        currentTranscriptIndex >= transcriptHistory.length - 1
                      }
                    >
                      <img src={images.ArrowRight} alt="ArrowRight" />
                    </button>
                  )}
                </div>

                <button
                  onClick={saveTranscriptClick}
                  className="btn btn-light regenerate"
                >
                  CONTINUE WITH PRACTICE GENERATION
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
      {loading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      {showErorModal && (
        <ErrorModalComponent
          modalText="Something went wrong! Please try again."
          onClose={closeModal}
          onClickTryAgain={handleNextClick}
        />
      )}
      <OpsModal show={showModal} onClose={closeModal} model={"Transcript"} />

      {showRegenerateTranscriptModal && (
        <RegenerateTranscript
          show={showRegenerateTranscriptModal}
          name={""}
          onClose={() => setShowRegenerateTranscriptModal(false)}
          onSubmit={regenerateTranscript}
          errorMessage={(errorMessage) => toast.error(errorMessage)}
        />
      )}
    </div>
  );
}

export default TranscriptGenerated;
