import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import HeaderComponent from "components/Header/Header";
import Button from "components/Button/SignupButton";
import { FormContext } from "../../../providers/FormContext";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer/Footer";

const VocablaryTopic = () => {
  const { formData, updateFormData } = useContext(FormContext);
  const [topic, setTopic] = useState(formData.topic || "");
  const [error, setError] = useState("");

  // const handleTopicChange = (e) => {
  //   setTopic(e.target.value);

  //   updateFormData("topic", e.target.value);
  //   if (e.target.value) {
  //     setError("");
  //   }
  // };

  // const handleNextClick = (e) => {
  //   if (!topic) {
  //     e.preventDefault();
  //     setError("Please enter a topic.");
  //     toast.error("Please enter a topic.");
  //   }
  // };
  const handleTopicChange = (e) => {
    const input = e.target.value;
    const wordCount = input
      .trim()
      .split(/\s+/)
      .filter((word) => word !== "").length;

    setTopic(input);

    // Update form data and clear the error if word count is sufficient
    if (wordCount >= 5) {
      updateFormData("topic", input);
      setError("");
    } else {
      // setError("The topic must contain at least 5 words.");
    }
  };

  const handleNextClick = (e) => {
    if (!topic) {
      e.preventDefault();
      setError("Please enter a topic.");
      toast.error("Please enter a topic.");
      return;
    }

    // Validate word count
    const wordCount = topic
      .trim()
      .split(/\s+/)
      .filter((word) => word !== "").length;
    if (wordCount < 5) {
      e.preventDefault();
      setError("The topic must contain at least 5 words.");
      toast.error("The topic must contain at least 5 words.");
      return;
    }

    setError(""); // Clear the error if everything is valid
  };

  return (
    <div className="min-screen screen5">
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="main-content">
          <h1 className="title">
            Vocabulary Flashcards for Interpreter Practice
          </h1>
          <p className="des">
            What kind of topic would you like your vocabulary to focus on?
            Please specify the topic to tailor the vocabulary list to your
            interests and needs. Whether it's for travel, business, daily
            conversation, or any other subject, your selection will help us
            generate a relevant and useful vocabulary list for your language
            learning journey.
          </p>
          <form className="form_">
            <label className="form-label">
              What kind of topic would you like your vocabulary to focus on?{" "}
              <span className="text-danger">*</span>
            </label>
            <textarea
              rows="3"
              cols="50"
              className="form-control"
              value={topic}
              onChange={handleTopicChange}
              placeholder="Please enter the topic you would like your vocabulary to focus on (Ex: workers compensation, probation, a political speech ,speech on climate change)"
            ></textarea>
            <p className="hint-text">
              {/* Please write at least 3 words about your topic. */}
              Please provide at least five words that best describe your topic.
            </p>
            {error && (
              <p className="text-danger" style={{ marginTop: "15px" }}>
                {error}
              </p>
            )}
          </form>
          <div className="btn-list">
            <Link to="/select-language" className="noDecoration">
              <Button className="small">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                </svg>
                Back
              </Button>
            </Link>
            <Link
              to="/vocabulary-language-level"
              className="noDecoration"
              onClick={handleNextClick}
            >
              <Button className="small">
                next
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                </svg>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default VocablaryTopic;
