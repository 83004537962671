import React, { useRef, useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WaveSurfer from "wavesurfer.js";
import "./Player.css";
import logo from "../../../assets/Images/logo-big.jpg";
import { API_URL } from "../../../constants";
import HeaderWhiteComponent from "../../../components/HeaderWhite/HeaderWhite";
import Footer from "../../../components/Footer/Footer";
import { fetchTranscriptService } from "../../../services/transcript.service";
import { UserContext } from "../../../providers/UserContext";
import images from "../../../images";

function AudioPlayer() {
  const { id } = useParams();
  const [playing, setPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(UserContext);
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTranscriptText = async () => {
      setLoading(true);
      try {
        const transcriptData = await fetchTranscriptService(
          id,
          currentUser.access
        );
        if (transcriptData) {
          const audios = transcriptData.save_audios || [];
          const audio = audios.find((item) => item.audio_file_path != null);
          if (audio) {
            const url = `${API_URL}/media/${audio.audio_file_path}`;
            setAudioUrl(url);
            console.log("Audio URL set:", url);
          } else {
            console.error("No valid audio file found");
          }
        }
      } catch (error) {
        console.error("Error fetching the transcript:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTranscriptText();
  }, [id, currentUser]);

  useEffect(() => {
    if (audioUrl) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "#ccc",
        progressColor: "#007bff",
        height: 80,
        barWidth: 2,
      });

      wavesurfer.current.load(audioUrl);

      wavesurfer.current.on("ready", () => {
        setLoading(false);
        if (playing) {
          wavesurfer.current.play();
        }
      });

      wavesurfer.current.on("error", (e) => {
        console.error("Error loading audio:", e);
        setLoading(false);
      });

      return () => {
        wavesurfer.current.destroy();
      };
    }
  }, [audioUrl]);

  const handlePlayPause = () => {
    if (wavesurfer.current) {
      wavesurfer.current.playPause();
      setPlaying(!playing);
      console.log("Playing state changed:", !playing); // Log new state
    }
  };

  const handleStop = () => {
    if (wavesurfer.current) {
      wavesurfer.current.stop();
      setPlaying(false);
    }
  };

  const backClick = () => {
    navigate(-1);
  };
  const EvaluateClick = () => {
    navigate(`/audio-recording-player/${id}`);
  };
  const ViewRecordingClick = () => {
    navigate(`/recording-with-keyword/${id}`);
  };

  return (
    <div className="min-screen player-container">
      <HeaderWhiteComponent className="headerr" />
      <div className="mainContentBox">
        <div className="player-bbutton-div">
          <div className="topBarcontentRight">
            <div className="userName">
              <button className="btn btn-primary" onClick={backClick}>
                <img
                  className="back-dash-right"
                  src={images.ArrowLeftwhite}
                  alt=""
                />
                Back
              </button>
            </div>
          </div>
          <div className="topBarcontentRight">
            <div className="userName">
              <button className="btn btn-primary" onClick={EvaluateClick}>
                Evaluate
              </button>
            </div>
            <div className="userName">
              <button className="btn btn-primary" onClick={ViewRecordingClick}>
                View Recordings
              </button>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="loader"></div>
        ) : (
          <div className="player-player-content">
            <div className="icon-container">
              <img
                src={logo}
                alt="logo"
                className="logoImg"
                style={{ width: "200px" }}
              />
            </div>
            <div className="waveform-container">
              <div ref={waveformRef} className="waveform"></div>
            </div>
            <div className="controls">
              <button className="control-button" onClick={handlePlayPause}>
                {playing ? "Pause" : "Play"}
              </button>
              <button className="control-button" onClick={handleStop}>
                Stop
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default AudioPlayer;
