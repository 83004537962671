import React, { useEffect, useRef, useState } from 'react';

function MicrophoneChecker() {
  const [isMicWorking, setIsMicWorking] = useState(false);
  const canvasRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const animationRef = useRef();

  useEffect(() => {
    const checkMicrophone = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setIsMicWorking(true);
        const audioContext = new AudioContext();
        audioContextRef.current = audioContext;
        
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 256;
        analyserRef.current = analyser;

        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);

        const drawWave = () => {
          if (!canvasRef.current || !analyser) return;

          const canvas = canvasRef.current;
          const context = canvas.getContext("2d");
          if (!context) return;

          const bufferLength = analyser.frequencyBinCount;
          const dataArray = new Uint8Array(bufferLength);
          analyser.getByteTimeDomainData(dataArray);

          context.clearRect(0, 0, canvas.width, canvas.height);
          context.lineWidth = 2;
          context.strokeStyle = isMicWorking ? 'green' : 'red';

          context.beginPath();
          const sliceWidth = (canvas.width * 1.0) / bufferLength;
          let x = 0;

          dataArray.forEach((item, index) => {
            const v = item / 128.0;
            const y = (v * canvas.height) / 2;
            index === 0 ? context.moveTo(x, y) : context.lineTo(x, y);
            x += sliceWidth;
          });
          context.lineTo(canvas.width, canvas.height / 2);
          context.stroke();
          
          animationRef.current = requestAnimationFrame(drawWave);
        };
        drawWave();

        return () => {
          stream.getTracks().forEach(track => track.stop());
          audioContext.close();
          if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
          }
        };
      } catch (error) {
        console.error("Microphone not accessible:", error);
        setIsMicWorking(false);
      }
    };

    checkMicrophone();
  }, [isMicWorking]);

  return (
    <div>
     { isMicWorking && <canvas ref={canvasRef} width={300} height={100} style={{ border: '1px solid #ccc' }} />}
     <p><b>Microphone Status</b>: 
        <span style={{ color: isMicWorking ? 'green' : 'red' }}>
          {isMicWorking ? "Microphone is working" : "Microphone is not accessible"}
        </span>
      </p>
    </div>
  );
}

export default MicrophoneChecker;
