import React, { useState, useEffect } from 'react';
import './Quiz.css';

const Quiz = ({ question, answers, correct, onAnswerSelect, selectedAnswer }) => {
  const [currentSelectedAnswer, setCurrentSelectedAnswer] = useState(null);

  // Reset selected answer when the question changes
  useEffect(() => {
    setCurrentSelectedAnswer(null); // Reset when question changes
  }, [question]);

  const handleAnswerClick = (answer) => {
    if (currentSelectedAnswer === null) { // Only allow selection if no answer has been chosen yet
      setCurrentSelectedAnswer(answer);
      onAnswerSelect(answer); // Notify parent of the selected answer
    }
  };

  const getRandomValueFromString = (commaSeparatedString) => {
    // Convert the string to an array by splitting at commas and trim each item
    const array = commaSeparatedString.split(',').map(item => item.trim());
  
    // Get a random index between 0 and array.length - 1
    const randomIndex = Math.floor(Math.random() * array.length);
  
    // Return the value at the random index
    return array[randomIndex];
  };

  return (
    <div className="quiz">
      <h4>{question}</h4>
      <div className="answers">
        {answers.map((answer, index) => (
          <div key={index} className="answer-container">
            <button
              onClick={() => handleAnswerClick(answer)}
              className={`answer ${currentSelectedAnswer === answer || (currentSelectedAnswer && answer === correct) ? (answer === correct ? 'correct' : 'incorrect') : ''}`}
              disabled={currentSelectedAnswer !== null}
            >
              {getRandomValueFromString(answer)}
            </button>
            {currentSelectedAnswer === answer && answer !== correct && (
              <p className="your-answer-label">Your Answer</p>
            )}

            {currentSelectedAnswer && answer === correct && (
              <p className="correct-answer-label">Correct Answer</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Quiz;
