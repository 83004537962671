import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import images from "../../images";
import Button from "../../components/Button/SignupButton";
import "./Forgot.css";
import { toast } from "react-toastify";
import { UserContext } from "../../providers/UserContext";
import { ForgotPassword } from "../../services/user.service";
import { REACT_APP_RESET_PASSWORD, REACT_APP_URL } from "../../constants";
// import { forgotPassword } from "../../services/user.service";

function ForgotScreen() {
  const navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [serverError, setServerError] = useState("");
  const { currentUser } = useContext(UserContext);
  const [resetUrl, setResetUrl] = useState("");

  useEffect(() => {
    if (currentUser.access) {
      navigate("/practice-dashboard");
      return;
    }
  }, [currentUser]);

  const openresetpasswordlink = () => {
    //   navigate(resetUrl);
    window.open(resetUrl, "_blank");
  };

  const handleForgotpassword = async (e) => {
    e.preventDefault();
    let valid = true;

    const userData = { email: Email };
    setEmailError("");
    setServerError("");

    if (!Email) {
      setEmailError("Email is required.");
      toast.error("Email is required.");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(Email)) {
      setEmailError("Invalid email address.");
      toast.error("Invalid email address.");
      valid = false;
    }

    if (!valid) return;

    try {
      const data = await ForgotPassword(userData);

      if (data) {
        let url = data.reset_url;

        url = url.replace(
          REACT_APP_RESET_PASSWORD,
          `${REACT_APP_URL}/reset-password/`
        );
        console.log(url, "url");

        setResetUrl(url);
        setEmail("");
        toast.success(
          "Password reset link successfully send to your email address . "
        );
      } else {
        toast.error("No access token found in response.");
      }
    } catch (error) {
      if (error.message.includes("Invalid email")) {
        toast.error("Please enter registered email Aaddress.");
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="min-screen screen1">
      <div className="main-content">
        <div className="login-form">
          <form onSubmit={handleForgotpassword}>
            <img src={images.logo} alt="logo" className="logo" />
            <h2>Forgot Password</h2>
            <p className="forgot-text">
              Please enter your registered email address
            </p>
            <div className="inputForm">
              <img src={images.mailIcon} alt="mail" className="inputicon" />
              <input
                type="text"
                className="custom-input"
                placeholder="Email"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {emailError && (
              <div className="login-error-message">{emailError}</div>
            )}
            {serverError && (
              <div className="login-error-message">{serverError}</div>
            )}
            <Button type="submit" className="success mt-4">
              Forgot Password
            </Button>
            {resetUrl && (
              <p className="reset-link">
                For reset password{" "}
                <span
                  onClick={openresetpasswordlink}
                  className="reset-link-span"
                >
                  Click here
                </span>
              </p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotScreen;
