import React, { useState } from "react";
import "./RegenerateTranscript.css";
import { toast } from "react-toastify";

const RegenerateTranscript = ({
  show,
  name,
  type,
  onClose,
  onSubmit,
  errorMessage,
}) => {
  const [testName, setTestName] = useState(name);
  const [localError, setLocalError] = useState("");
  const handleInputChange = (e) => {
    setTestName(e.target.value);
    if (e.target.value.trim() !== "") {
      setLocalError("");
    }
  };

  const handleSubmit = () => {
    // if (testName.trim() === "") {
    //   toast.error("Please enter a practice name.");
    //   // setLocalError("Please enter a practice name.");
    //   return;
    // }
    onSubmit(testName);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="regenerate-transcript-modal" onClick={onClose}>
      <div
        className="regenerate-transcript-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="regen-trans-top">
          <span
            className="regenerate-transcript-close-button"
            onClick={onClose}
          >
            &times;
          </span>
        </div>
        <h3 className="text-center mb-3">Regenerate {type}</h3>
        <div className="mb-3">
          <p>
            Why do you want to regenerate this {type}? <br />Please let us know so
            we can improve the {type}.
          </p>
        </div>
        <div>
          {/* <input
            type="text"
            placeholder="Enter practice test name"
            value={testName}
            className="regenerate-transcript-test-input"
            onChange={handleInputChange}
          /> */}
          <textarea
            className="text scrollbar m-0 form-control textarea-dark-border"
            value={testName}
            onChange={handleInputChange}
          />
        </div>
        {localError && (
          <p className="regenerate-transcript-error-message">{localError}</p>
        )}
        {errorMessage && !localError && (
          <p className="error-message">{errorMessage}</p>
        )}
        <div className="regenerate-transcript-test-submit">
          <button id="regenerate-button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegenerateTranscript;
