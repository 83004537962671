import React from "react";
import "./AudioCancle.css";

const AudioCancelModal = ({ show, handleClose, handleConfirm }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="audio-cancel-modal-overlay">
      <div className="audio-cancel-modal">
        <div className="audio-cancel-modal-header">
          <h3>Cancel Audio Recording</h3>
          <button className="audio-cancel-close-btn" onClick={handleClose}>
            &times;
          </button>
        </div>
        <div className="audio-cancel-modal-body">
          <p>Are you sure you want to cancel this audio recording?</p>
        </div>
        <div className="audio-cancel-modal-footer">
          <button
            className="audio-cancel-btn"
            onClick={() => {
              handleConfirm();
              window.location.reload();
            }}
          >
            Yes
          </button>
          <button className="audio-cancel-btn" onClick={handleClose}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default AudioCancelModal;
