// Importing images
import logo from './assets/Images/MainLogo.png';
import bigLogo from './assets/Images/logo-big.jpg';

import mailIcon from './assets/Images/mail.svg';
import user from "./assets/Images/user.png";
import lockIcon from './assets/Images/lock.svg';
import slider1 from './assets/Images/slider1.png';
import slider2 from './assets/Images/slider2.png';
import slider3 from './assets/Images/slider3.png';
import edit from "./assets/Images/edit.svg";
import ArrowRight from "./assets/Images/arrow-right.svg";
import ArrowLeft from "./assets/Images/arrow-left.svg";
import ArrowLeftwhite from "./assets/Images/arrow-left-white.png";

import user1 from "./assets/Images/user1.png";
import userss from "./assets/Images/users.png";
import VocabModal from "./assets/Images/loading-gif.gif";
import deleteimg from "./assets/Images/delete.svg";
import iconlabel from "./assets/Images/congraulation-icon.png";
import share from "./assets/Images/share.png";
import pluscircle from "./assets/Images/plus-circle.svg";
import femaleLogo from "./assets/Images/profile-girl-icon.png";
import maleLogo from "./assets/Images/mail logo.png";
import PlayAudio from "./assets/Images/play-circle.png";
import play from "./assets/Images/play.png";
import logoplay from "./assets/Images/logo.png";
import complete from "./assets/Images/Completed.svg";
import evalute from "./assets/Images/Evaluate.png";
import incomplete from "./assets/Images/Incomplete.svg";
import interpretedMike from "./assets/Images/InterpretRecord.png";
import drillvocablry from "./assets/Images/Drillvocabulary.png";
import deletDash from "./assets/Images/trash-2.png";
import leftarrow from "./assets/Images/arrow-left.png";
import rightarrow from "./assets/Images/arrow-right.png";
import mike from "./assets/Images/mike.png";
import sadface from "./assets/Images/frown.png";
import eye from "./assets/Images/eye.png";
import eyeOff from "./assets/Images/eye-off.svg";
import editpage from "./assets/Images/edit.svg";
import copy from "./assets/Images/copy.png";
import userAvtarBlack from "./assets/Images/user-avtar-black.png";
import stop from "./assets/Images/stop.svg";
import pause from "./assets/Images/pause.svg";
import cancle from "./assets/Images/cancle.svg";
import wrong from "./assets/Images/went-wrong.gif";
import facebook from "./assets/Images/facebook.svg";
import twitter from "./assets/Images/prime_twitter.svg";
import congratulation from "./assets/Images/congratulation-bg.png";
import checkEdit from "./assets/Images/check-circle.png";
import Congress from "./assets/Images/congo-main.png";
import satisfaction from "./assets/Images/satisfaction.png";
import youDidIt from "./assets/Images/you_did_it.gif";
import audioWave from "./assets/Images/audio-wave.png";

// Export images
const images = {
    userAvtarBlack,
    Congress,
    logo,
    checkEdit,
    wrong,
    congratulation,
    copy, facebook,
    twitter,
    stop,
    pause,
    editpage, ArrowLeftwhite,
    bigLogo,
    sadface,
    cancle,
    mike,
    evalute,
    rightarrow,
    leftarrow,
    interpretedMike,
    drillvocablry,
    incomplete,
    deletDash,
    PlayAudio,
    play,
    complete,
    pluscircle,
    logoplay,
    femaleLogo,
    maleLogo,
    mailIcon,
    lockIcon,
    slider2,
    iconlabel,
    slider1,
    slider3,
    eye,
    eyeOff,
    share,
    user,
    deleteimg,
    edit,
    ArrowRight,
    VocabModal,
    ArrowLeft,
    user1,
    userss,
    satisfaction,
    youDidIt,
    audioWave,
};

export default images;
