import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderComponent from "../../../components/Header/Header";
import { toast } from "react-toastify";
import { getVocabById } from "../../../services/user.service";
import languagesData from "../../../language.json";
import Select from "react-select";
import ModalComponent from "../../../components/Modal/VocabModal/VocabModal";
import { useDispatch } from "react-redux";
import { setTempVocabulary, setVocabList } from "../../../redux/vocabSlice";
import Footer from "../../../components/Footer/Footer";
import {
  editgenerateVocabulary,
  generateVocabulary,
} from "../../../services/vocab.service";
import { UserContext } from "../../../providers/UserContext";
import ErrorModalComponent from "../../../components/Modal/TryAgainModal/TryAgainModal";
import { REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE } from "../../../constants";

const EditVocabulary = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useContext(UserContext);
  // console.log("id:", id);
  const [showErorModal, setShowErosModal] = useState(false);

  const [activeStep, setActiveStep] = useState("vocabulary-terms");
  const [apiError, setApiError] = useState("");
  const [vocabularyDetails, setVocabularyDetails] = useState({});

  const [showModal, setShowModal] = useState(false);
  const steps = [
    {
      primaryStep: true,
      stepName: "vocabulary-terms",
      component: "",
      heading: "Vocabulary Flashcards for Interpreter Practice",
      content: `To create a tailored set of vocabulary flashcards for your interpreter practice, please answer the following questions. You can skip any question if you prefer, but more details will help us create a more precise practice experience.`,
    },
    {
      primaryStep: false,
      stepName: "select-language",
      component: "",
      heading: "Vocabulary Flashcards for Interpreter Practice",
      content: `Please choose the source and target languages to generate the vocabulary list. This will enable us to provide you with the most accurate and relevant vocabulary for your language learning needs.`,
    },
    {
      primaryStep: false,
      stepName: "topic",
      component: "",
      heading: "Vocabulary Flashcards for Interpreter Practice",
      content: `How challenging should it be? Please select from the provided difficulty levels to ensure the vocabulary list matches your current language proficiency and learning goals.`,
    },
    {
      primaryStep: false,
      stepName: "language-level",
      component: "",
      heading: "Vocabulary Flashcards for Interpreter Practice",
      content: `How challenging should it be? Please select from the provided difficulty levels to ensure the vocabulary list matches your current language proficiency and learning goals.`,
    },
    // {
    //   primaryStep: false,
    //   stepName: "geographical-area",
    //   component: "",
    //   heading: "Vocabulary Flashcards for Interpreter Practice",
    //   content: `Please specify the geographical region where this speech is most commonly given. This information will help us tailor the transcript to better reflect local dialects, terminologies, and cultural context.`,
    // },
  ];
  const closeModal = () => {
    // handleNext();
    setShowErosModal(false);
  };

  useEffect(() => {
    if (currentUser && currentUser.access) {
      getVocab();
    }
  }, [id, currentUser]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgba(255, 255, 255, 0.08)",
      borderRadius: "8px",
      color: "#fff",
      padding: "8px",
      boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)", // Add slight shadow
      border: "2px solid rgba(255, 255, 255, 0.3)", // Matches the border from the image
      minHeight: "50px",
      // "&:hover": {
      //   borderColor: "#FFFFFF2E", // Hover effect for interactivity
      // },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#00081F",
      borderRadius: "8px",
      color: "white",
      padding: "10px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#FFFFFF47" : "#00081F", // Focused option color
      color: state.isFocused ? "white" : "white", // Text color when selected
      padding: "10px",
      minHeight: "50px",
      borderRadius: "8px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white", // White text for selected option
    }),
    input: (provided) => ({
      ...provided,
      color: "white", // White input text
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#a7a7a7", // Lighter gray for placeholder text
    }),
    indicatorSeparator: () => ({
      display: "none", // Remove the separator for a cleaner look
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white", // White dropdown arrow
      "&:hover": {
        color: "white", // Orange arrow on hover
      },
    }),
  };

  const getVocab = async () => {
    const payload = {
      identifier: id,
    };

    const response = await getVocabById(payload, currentUser.access);
    console.log(response.keyword);
    setVocabularyDetails(response.keyword);
  };

  const languageOptions = languagesData.map((language) => ({
    value: language.fullname,
    label: language.fullname,
  }));

  const getCurrentStepIndex = () => {
    return steps.findIndex((step) => step.stepName === activeStep);
  };

  const handleNext = async () => {
    setShowErosModal(false);

    const currentIndex = getCurrentStepIndex();
    if (activeStep === "vocabulary-terms") {
      const count = parseInt(vocabularyDetails.num_keywords, 10);

      if (vocabularyDetails.num_keywords === "") {
        toast.error("Vocabulary count cannot be empty.");
        return false;
      }

      if (isNaN(count)) {
        toast.error("Please enter a valid number.");
        return false;
      }

      if (count <= 4 || count > 25) {
        toast.error(
          "Please enter a number greater than or equal to 5 and less than or equal to 25."
        );
        return false;
      }
    } else if (activeStep === "select-language") {
      if (
        !vocabularyDetails.source_language ||
        !vocabularyDetails.target_language
      ) {
        toast.error("Please select both source and target languages.");
        return false;
      }
    } else if (activeStep === "topic") {
      if (!vocabularyDetails.topic) {
        toast.error("Please enter a topic.");
        return false;
      }

      // Validate word count
      const wordCount = vocabularyDetails.topic
        .trim()
        .split(/\s+/)
        .filter((word) => word !== "").length;
      if (wordCount < 5) {
        toast.error("The topic must contain at least 5 words.");
        return false;
      }
    } else if (activeStep === "language-level") {
      if (!vocabularyDetails.level) {
        toast.error("Please select a difficulty level.");
        return false;
      }
    } else if (activeStep === "geographical-area") {
      if (!vocabularyDetails.region) {
        toast.error("Please specify the geographical region.");
        return;
      }
    }

    if (currentIndex < steps.length - 1) {
      setActiveStep(steps[currentIndex + 1].stepName);
    }
    if (currentIndex === steps.length - 1) {
      setShowModal(true);
      setApiError("");

      // const token = localStorage.getItem("accessToken");
      if (!currentUser) {
        console.error("No access token found");
        setShowModal(false);
        return;
      }

      const maxRetries = 2; // Maximum number of retries
      let attempts = 0; // Number of attempts made
      let success = false;
      const adjustedVocabularyDetails = {
        ...vocabularyDetails,
        num_keywords: parseInt(vocabularyDetails.num_keywords, 10) + 3,
      };

      while (attempts < maxRetries && !success) {
        try {
          const data = await editgenerateVocabulary(adjustedVocabularyDetails);

          if (
            data &&
            data.keywords &&
            data.keywords.keywords &&
            Array.isArray(data.keywords.keywords.lists)
          ) {
            console.log(
              "data.keywords.keywords.lists",
              data.keywords.keywords.lists[0].length
            );
            dispatch(setVocabList(data.keywords.keywords.lists));
            success = true;
            toast.success("Vocabulary generated Successfully");
            navigate(`/vocabulary/keywords/${id}`, {
              state: { vocabularyDetails: vocabularyDetails },
            });
            break;
          } else {
            setShowModal(false);

            setShowErosModal(true);
            console.error("Invalid data format", data);
          }
          break;
        } catch (error) {
          setShowModal(false);
          setShowErosModal(true);
          console.error(
            `API call failed (Attempt ${attempts + 1}/${maxRetries}):`,
            error
          );
          attempts += 1;

          if (attempts >= maxRetries) {
            setApiError(
              "Error in Generating. Go back to Dashboard and please try again later."
            );
            toast.error(
              "Error in Generating. Go back to Dashboard and please try again later."
            );
            setShowModal(false);

            setShowErosModal(true);
          } else {
            console.log("Retrying the API call...");
          }
        }
      }
    }
  };

  const handleBack = () => {
    const currentIndex = getCurrentStepIndex();
    if (currentIndex > 0) {
      setActiveStep(steps[currentIndex - 1].stepName);
    }
  };
  const handleChange = (selectedOption, field) => {
    console.log(field, selectedOption); // Log the value and field

    // Always update the state regardless of whether selectedOption is empty or not
    setVocabularyDetails((prevDetails) => ({
      ...prevDetails,
      [field]: selectedOption,
    }));

    // console.log("Updated vocabularyDetails:", vocabularyDetails); // Check the updated state
  };

  return (
    <div className="min-screen screen3">
      <HeaderComponent />
      {showModal && (
        <ModalComponent modalText="Generating Vocabulary. Please wait..." />
      )}

      {steps.map(
        (step) =>
          activeStep === step.stepName && (
            <div className="mainContentBox" key={step.stepName}>
              <div className="main-content">
                <h1 className="title">{step.heading}</h1>
                <p className="des">{step.content}</p>
                <form className="form_">
                  {activeStep === "vocabulary-terms" && (
                    <>
                      <label className="form-label">
                        How many vocabulary terms do you want to drill in this
                        practice? <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        value={vocabularyDetails.num_keywords}
                        onChange={(e) =>
                          handleChange(e.target.value, "num_keywords")
                        }
                        className="form-control"
                        placeholder="Please enter the number of vocabulary terms you want to drill (Ex: 25)"
                      />
                    </>
                  )}
                  {activeStep === "select-language" && (
                    <>
                      <label className="form-label">
                        In what language combination?{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="row">
                        <div className="col-md-6 select-language mb-3">
                          <Select
                            options={languageOptions}
                            onChange={(e) =>
                              handleChange(e.value, "source_language")
                            }
                            placeholder="Select source language"
                            styles={customStyles}
                            value={{
                              value: vocabularyDetails.source_language,
                              label: vocabularyDetails.source_language,
                            }}
                            // defaultValue={vocabularyDetails.source_language}
                          />
                        </div>
                        <div className="col-md-6">
                          <Select
                            options={languageOptions}
                            onChange={(e) =>
                              handleChange(e.value, "target_language")
                            }
                            placeholder="Select source language"
                            styles={customStyles}
                            value={{
                              value: vocabularyDetails.target_language,
                              label: vocabularyDetails.target_language,
                            }}
                            // defaultValue={{ value: vocabularyDetails.target_language, label: vocabularyDetails.target_language}}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {activeStep === "topic" && (
                    <>
                      <label className="form-label">
                        What kind of topic would you like your vocabulary to
                        focus on? <span className="text-danger">*</span>
                      </label>
                      <textarea
                        rows="3"
                        cols="50"
                        className="form-control"
                        value={vocabularyDetails.topic}
                        onChange={(e) => handleChange(e.target.value, "topic")}
                        placeholder="Please enter the topic you would like your vocabulary to focus on (Ex: workers compensation, probation, a political speech on climate change)"
                      ></textarea>
                      <p className="hint-text">
                        Please provide at least five words that best describe
                        your topic.
                      </p>
                    </>
                  )}
                  {activeStep === "language-level" && (
                    <>
                      <label className="form-label mb-4">
                        How challenging should it be?{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="input-box">
                        <label className="custom-check form-label">
                          Beginner
                          <input
                            type="radio"
                            name="level"
                            value="beginner"
                            checked={vocabularyDetails.level === "beginner"}
                            onChange={(e) => handleChange("beginner", "level")}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="input-box">
                        <label className="custom-check form-label">
                          Intermediate
                          <input
                            type="radio"
                            name="level"
                            value="intermediate"
                            checked={vocabularyDetails.level === "intermediate"}
                            onChange={(e) =>
                              handleChange("intermediate", "level")
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="input-box">
                        <label className="custom-check form-label">
                          Advanced
                          <input
                            type="radio"
                            name="level"
                            value="advanced"
                            checked={vocabularyDetails.level === "advanced"}
                            onChange={(e) => handleChange("advanced", "level")}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </>
                  )}
                  {activeStep === "geographical-area" && (
                    <>
                      <label className="form-label">
                        What area of the world would this speech most commonly
                        be given? <span className="text-danger">*</span>
                      </label>
                      <textarea
                        rows="3"
                        cols="50"
                        className="form-control"
                        value={vocabularyDetails.region}
                        onChange={(e) => handleChange(e.target.value, "region")}
                        placeholder="Please enter the geographical region where this speech is most commonly given."
                      ></textarea>
                      {apiError && <p className="text-danger">{apiError}</p>}
                    </>
                  )}
                </form>
                <div className="btn-list">
                  <button
                    className="btn small"
                    onClick={handleBack}
                    disabled={getCurrentStepIndex() === 0}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      fill="currentColor"
                    >
                      <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                    </svg>
                    Back
                  </button>
                  <button className="btn small" onClick={handleNext}>
                    next
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      fill="currentColor"
                    >
                      <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          )
      )}
      <Footer />
      {showErorModal && (
        <ErrorModalComponent
          modalText="Something went wrong! Please try again."
          onClose={closeModal}
          onClickTryAgain={handleNext}
        />
      )}
    </div>
  );
};

export default EditVocabulary;
