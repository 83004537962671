import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setTranscriptText,
  addTranscriptToHistory,
  updateIdentifier,
  clearTranscriptHistory,
} from "../../../redux/vocabSlice";
import { FormContext } from "../../../providers/FormContext";
import HeaderComponent from "components/Header/Header";
import ModalComponent from "../../../components/Modal/VocabModal/VocabModal";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer/Footer";
import { submitTranscriptData } from "../../../services/transcript.service";
import ErrorModalComponent from "../../../components/Modal/TryAgainModal/TryAgainModal";
import { UserContext } from "../../../providers/UserContext";
const TranscriptAdditionalInfo = () => {
  const { formData, updateFormData } = useContext(FormContext);
  const { currentUser } = useContext(UserContext);
  const [usertoken] = useState(currentUser.access || "");
  const navigate = useNavigate();
  const { id } = useParams();
  const [showErorModal, setShowErorModal] = useState(false);

  const identifier = id;
  const [additionalInfo, setAdditionalInfo] = useState(
    formData.additional_info || ""
  );
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const closeModal = () => {
    // handleNextClick();
    setShowErorModal(false);
  };
  useEffect(() => {
    dispatch(clearTranscriptHistory());
    const newIdentifier = localStorage.getItem("practiceIdentifier");
    if (newIdentifier) {
      dispatch(updateIdentifier(newIdentifier));
    }
  }, [dispatch, currentUser]);

  const handleInputChange = (e) => {
    setAdditionalInfo(e.target.value);
    updateFormData("additional_info", e.target.value);
  };

  const validateInput = () => {
    if (additionalInfo.trim() === "") {
      setError("Additional information cannot be empty.");
      toast.error("Additional information cannot be empty.");
      return false;
    }
    setError("");
    return true;
  };

  const handleNextClick = async () => {
    setShowErorModal(false);

    // const accessToken = localStorage.getItem("accessToken");

    setShowModal(true);

    if (!validateInput()) {
      setShowModal(false);
      return;
    }

    const dataToSend = {
      identifier: id,
      transcript_length: formData.transcript_length,
      additional_instructions: additionalInfo,
      conversation_type:formData.conversationType,
      speaker: formData.speaker_info,
      person1: formData.person1,
      person2: formData.person2,
      feedbacks:"",
    };
    try {
      const result = await submitTranscriptData(dataToSend, currentUser.access);

      dispatch(setTranscriptText(result.transcript));
      dispatch(
        addTranscriptToHistory({
          transcript: result.transcript,
          identifier: identifier,
        })
      );
      toast.success("Transcript generated Successfully");
      navigate(`/transcript-generated/${id}`);
    } catch (error) {
      console.error("Error submitting data:", error);
      setShowModal(false);
      setShowErorModal(true);
      // toast.error("Something went wrong! Please try again after some time.");
      setError("Something went wrong! Please try again after some time.");
      // setTimeout(() => {
      //   navigate("/practice-dashboard");
      // }, 4000);
    } finally {
      setShowModal(false);
      // setLoading(false);
    }

    // const maxRetries = 2;
    // let retryCount = 0;
    // let success = false;

    // while (retryCount <= maxRetries && !success) {
    //   try {
    //     const response = await fetch(REACT_APP_TRANSCRIPT_API, {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${accessToken}`,
    //       },
    //       body: JSON.stringify(dataToSend),
    //     });

    //     if (response.ok) {
    //       const result = await response.json();
    //       dispatch(setTranscriptText(result.transcript));
    //       dispatch(
    //         addTranscriptToHistory({
    //           transcript: result.transcript,
    //           identifier: identifier,
    //         })
    //       );
    //       success = true;
    //       navigate(`/transcript-generated/${id}`);
    //     } else {
    //       const errorText = await response.text();
    //       console.error("Error response:", errorText);
    //       if (response.status === 401) {
    //         throw new Error("Unauthorized. Please check your credentials.");
    //       } else if (response.status >= 500) {
    //         retryCount += 1;
    //         if (retryCount > maxRetries) {
    //           throw new Error(
    //             "Received an incomplete or invalid JSON response from Gemini API."
    //           );
    //         }
    //         await new Promise((resolve) => setTimeout(resolve, 1000));
    //       } else {
    //         throw new Error(
    //           `HTTP error! Status: ${response.status}, Response: ${errorText}`
    //         );
    //       }
    //     }
    //   } catch (error) {
    //     console.error("Error submitting data:", error);
    //     if (retryCount > maxRetries) {
    //       setError("Something went wrong! Please try again after some time.");
    //       setTimeout(() => {
    //         navigate("/practice-dashboard");
    //       }, 2000);
    //       break;
    //     }
    //   } finally {
    //     if (success || retryCount > maxRetries) {
    //       setShowModal(false);
    //       setLoading(false);
    //     }
    //   }
    // }
  };

  return (
    <div className="min-screen screen14">
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="main-content">
          <h1 className="title">Transcript Generation</h1>
          <p className="des">
            Is there anything else you would like to let us know? Please provide
            any additional information or special instructions that will help us
            generate the most accurate and useful transcript for your needs.
          </p>
          <form className="form_" onSubmit={handleNextClick}>
            <label className="form-label">
              Is there anything else you would like to let us know?{" "}
              <b style={{ color: "red" }}>*</b>
            </label>
            <textarea
              className="form-control"
              rows="3"
              value={additionalInfo}
              onChange={handleInputChange}
              placeholder="Additional instructions or preferences for the transcript? (Ex: colloquial language, scientific terminology, emotional depth, persuasive rhetoric, etc.)"
              required
            ></textarea>
          </form>
          {error && (
            <div className="trans-error-message">
              <span>{error}</span>
            </div>
          )}
          <div className="btn-list">
            <Link to={`/transcript-speaker/${id}`} className="noDecoration">
              <button className="small">
                <svg
                  xmlns="http:www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                </svg>
                Back
              </button>
            </Link>
            <Link className="noDecoration">
              <button className="small" onClick={handleNextClick}>
                Submit
                <svg
                  xmlns="http:www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                </svg>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
      {showModal && (
        <ModalComponent modalText="Generating Transcript. Please wait..." />
      )}
      {showErorModal && (
        <ErrorModalComponent
          modalText="Something went wrong! Please try again."
          onClose={closeModal}
          onClickTryAgain={handleNextClick}
        />
      )}
    </div>
  );
};

export default TranscriptAdditionalInfo;
