import React, { useState, useEffect, useRef } from "react";
import "./Keyword.css";

const Keyword = ({ word, translations, callbackForAudio }) => {
  const [isRight, setIsRight] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isResearch, setIsResearch] = useState(false);
  const modalRef = useRef(null);
  const handleOpenModal = () => {
    callbackForAudio();
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    callbackForAudio();
  };

  const handleRight = () => {
    callbackForAudio();
    setIsRight(true);
    handleCloseModal();
  };

  const handleWrong = () => {
    callbackForAudio();
    setIsRight(false);
    handleCloseModal();
  };
  const handleResearch = () => {
    callbackForAudio();
    setIsResearch(true);
    setIsRight(null);
    handleCloseModal();
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCloseModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);
  return (
    <>
      <span
        onClick={handleOpenModal}
        className={
          isResearch
            ? "keyword_blue"
            : isRight === null
            ? "keyword_default"
            : isRight
            ? "keyword_green"
            : "keyword_red"
        }
        style={{ cursor: "pointer", marginRight: "4px" }}
      >
        {word}
      </span>

      {isModalOpen && (
        <div className="keyword-modal">
          <div className="keyword-modal-content" ref={modalRef}>
            {/* <h4>Translations for "{word}"</h4> */}
            <span
              aria-hidden="true"
              className="keywpord-close"
              onClick={handleCloseModal}
            >
              &times;
            </span>
            <div className="keyword-input-group">
              <label>Source Language</label>
              <input type="text" value={word} readOnly />
            </div>
            <div className="keyword-input-group">
              <label>Target Language</label>
              <textarea type="text" value={translations} readOnly />
            </div>
            <div className="keyword-modal-buttons">
              <button className="btn correct-btn" onClick={handleRight}>
                Correct
              </button>
              <button className="btn incorrect-btn" onClick={handleWrong}>
                Incorrect
              </button>
              <button className="btn research-btn" onClick={handleResearch}>
                Research
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Keyword;
