import React from "react";
import "./QuizConfirm.css"; // Custom CSS for the modal

const QuizModal = ({ isVisible, onConfirm, onCancel, children, onClose }) => {
  if (!isVisible) return null; // Don't render if the modal isn't visible

  return (
    <div className="modal-overlay">
      <div className="quiz-modal">
        <span aria-hidden="true" onClick={onClose} className="Quiz-close-btn">
          &times;
        </span>
        <div className="quiz-modal-content">
          {children} {/* Render any children (content) passed to the modal */}
        </div>
        <div className="quiz-modal-buttons">
          <button onClick={onConfirm} className="confirm-button">
            Confirm
          </button>
          <button onClick={onCancel} className="cancel-button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuizModal;
