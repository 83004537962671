import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderComponent from "components/Header/Header";
import Button from "components/Button/SignupButton";
import { Link } from "react-router-dom";
import ConfirmDeleteModal from "../../../components/Modal/DeleteModal/VocabDeletModal";
import {
  setVocabList,
  regenerateKeyword,
  navigateKeywordHistory,
} from "../../../redux/vocabSlice";
import { useSelector, useDispatch } from "react-redux";
import { FormContext } from "../../../providers/FormContext";
import Loader from "../../../components/Loader/Loader";
import Footer from "../../../components/Footer/Footer";
import { toast } from "react-toastify";
import { UserContext } from "../../../providers/UserContext";
import {
  editgenerateVocabulary,
  saveVocabPractice,
  updateVocabulary,
} from "../../../services/vocab.service";
import { REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE } from "../../../constants";
import TestNameModal from "../../../components/Modal/Practise_test_modal/TestNameModal";
import VocabListModal from "../../../components/VocablaryList/VocablaryList";
import images from "../../../images";
import RegenerateTranscript from "../../../components/Modal/RegenerateTranscript/RegenerateTranscript";
import OpsModal from "../../../components/Modal/OppsModal/OpsModal";

const EditVocabularyKeywords = () => {
  const vocabList = useSelector((state) => state.vocab.vocabList);
  const { formData } = useContext(FormContext);
  const { currentUser } = useContext(UserContext);

  const dispatch = useDispatch();
  const [displayedList, setDisplayedList] = useState(0);
  const [focusedKeywordIndex, setFocusedKeywordIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [highlightedCardIndex, setHighlightedCardIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showCompleteVocabularyModal, setShowCompleteVocabularyModal] =
    useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const cardRefs = useRef([]);
  const [showRegenerateVocabularyModal, setShowRegenerateVocabularyModal] =
    useState(false);
  const [temp, setTemp] = useState([]); // New array for last 3 items
  const [regenerationCounts, setRegenerationCounts] = useState({}); // State to track counts
  const [keywords, setKeywords] = useState([]); // State to track counts
  const [showTestNameModal, setShowTestNameModal] = useState(false);
  const { vocabularyDetails } = location.state || {};
  const { id } = useParams();
  const [showOpsModal, setShowOpsModal] = useState(false);

  const [totalRegenerationCount, setTotalRegenerationCount] = useState(0); // Use state for regeneration count
  const maxRegenerations = 3;
  // On initial render, remove last 3 items from vocabList and store in temp
  useEffect(() => {
    // Ensure that vocabularyDetails and vocabList are both valid before proceeding
    if (
      vocabularyDetails &&
      vocabList &&
      vocabList[displayedList]?.length > vocabularyDetails?.num_keywords
    ) {
      const tempArray = vocabList[displayedList].slice(-3); // Take the last 3 items
      const updatedVocabList = vocabList[displayedList].slice(0, -3); // Remove the last 3 items

      setTemp(tempArray); // Store the last 3 items in temp state
      dispatch(
        setVocabList([...vocabList.slice(0, displayedList), updatedVocabList])
      );
      setKeywords(updatedVocabList); // Set the updated vocabulary list
    }
  }, [vocabList, displayedList, dispatch, vocabularyDetails]);

  // Function to regenerate a keyword
  const handleRegenerate = (index) => {
    // Get the current regeneration count for this keyword
    setTotalRegenerationCount((prevTotalCount) => {
      // Check if the combined regeneration limit has been reached
      if (prevTotalCount >= maxRegenerations) {
        // toast.error("No keywords available for regeneration.");
        setShowOpsModal(true);
        return prevTotalCount; // Return the old value to avoid updating
      }

      const currentCount = regenerationCounts[index] || 0;

      // Check if the count is less than 3 and temp array is not empty
      if (temp.length > 0) {
        // Get the first item from temp
        const newKeyword = temp[0]; // New keyword to replace the current one

        // Update the keywords array at the specified index
        const updatedKeywords = [...keywords];
        updatedKeywords[index] = newKeyword; // Replace with new keyword
        toast.success("Vocabulary Generated Successfully");
        // Set the new state
        setKeywords(updatedKeywords);

        // Remove the first item from temp
        setTemp((prevTemp) => prevTemp.slice(1));

        // Update the regeneration count
        setRegenerationCounts((prevCounts) => ({
          ...prevCounts,
          [index]: currentCount + 1,
        }));
        return prevTotalCount + 1;
      } else {
        toast.error(
          "You can only regenerate this keyword 3 times or no keywords available."
        );
      }
    });
  };

  const handleSaveClick = () => {
    dispatch(setVocabList(vocabList));
    toast.success("Vocabulary Edited Successfully");
    setFocusedKeywordIndex(null);
    setHighlightedCardIndex(null);
  };

  const handleCancelClick = () => {
    setFocusedKeywordIndex(null);
    setHighlightedCardIndex(null);
  };

  const handleDeleteClick = (index) => {
    setFocusedKeywordIndex(index);
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    if (focusedKeywordIndex === null || vocabList.length === 0) {
      console.log("No item to delete or vocabList is empty");
      return;
    }

    // Update vocabList by removing the item at focusedKeywordIndex
    const updatedVocabList = vocabList.map((list, listIndex) =>
      listIndex === displayedList
        ? list.filter((_, itemIndex) => itemIndex !== focusedKeywordIndex)
        : list
    );

    console.log("Updated Vocab List:", updatedVocabList); // Check the updated list

    // Dispatch the updated vocabList to the Redux store
    dispatch(setVocabList(updatedVocabList));

    // Update keywords state after deletion
    setKeywords(updatedVocabList[displayedList]);

    // Close modal and reset indexes
    setShowModal(false);
    toast.success("Vocabulary Deleted Successfully");
    setFocusedKeywordIndex(null);
    setHighlightedCardIndex(null);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFocusedKeywordIndex(null);
    setHighlightedCardIndex(null);
  };
  useEffect(() => {
    if (location.state && location.state.index !== undefined) {
      setFocusedKeywordIndex(location.state.index);
    }
  }, [location.state]);

  useEffect(() => {
    if (focusedKeywordIndex !== null && cardRefs.current.length > 0) {
      const index = focusedKeywordIndex;
      if (index >= 0 && index < vocabList[displayedList]?.length) {
        cardRefs.current[index]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setHighlightedCardIndex(index);
      }
    }
  }, [focusedKeywordIndex, displayedList, vocabList, vocabularyDetails]);

  // Function to view complete vocabulary
  const handleViewCompleteVocabulary = () => {
    setShowCompleteVocabularyModal(true);
  };

  // Function to handle input changes
  const handleChange = (index, field, value) => {
    // Clone the entire keywords array to avoid direct mutation
    const updatedVocabList = [...keywords]; // Create a shallow copy of the array

    // Clone the specific item that is being updated to avoid direct mutation
    const updatedItem = { ...updatedVocabList[index] };

    // Update the field (e.g., source_language or target_language) with the new value
    updatedItem[field] = value;

    // Replace the old item with the updated one in the cloned array
    updatedVocabList[index] = updatedItem;

    // Dispatch the new vocab list to the Redux store or set local state
    console.log("updatedVocabList", updatedVocabList);
    setKeywords(updatedVocabList);
    // dispatch(setVocabList([...vocabList.slice(0, displayedList), ...updatedVocabList]));
  };

  const handleNavigateHistory = (index, direction) => {
    dispatch(
      navigateKeywordHistory({
        listIndex: displayedList,
        keywordIndex: index,
        direction,
      })
    );
  };

  const showRegenerateVocabulary = () => {
    if (totalRegenerationCount >= maxRegenerations) {
      setShowOpsModal(true);
    } else {
      setShowRegenerateVocabularyModal(true);
    }
  };

  const regenerateVocabulary = async (feedbacks) => {
    setLoading(true);
    const maxRetries = 2;
    let attempts = 0;
    let success = false;

    while (attempts < maxRetries && !success) {
      try {
        const adjustedVocabularyDetails = {
          topic: vocabularyDetails.topic,
          source_language: vocabularyDetails.source_language,
          target_language: vocabularyDetails.target_language,
          level: vocabularyDetails.level,
          region: vocabularyDetails.region,
          num_keywords: parseInt(vocabularyDetails.num_keywords, 10),
          num_lists: REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE,
          user_id: currentUser.email,
          practice_name: vocabularyDetails.testName,
          feedbacks: feedbacks,
        };
        const data = await editgenerateVocabulary(adjustedVocabularyDetails);

        if (
          data &&
          data.keywords &&
          data.keywords.keywords &&
          Array.isArray(data.keywords.keywords.lists)
        ) {
          console.log(
            "data.keywords.keywords.lists",
            data.keywords.keywords.lists[0].length
          );
          dispatch(setVocabList(data.keywords.keywords.lists));
          success = true;
          toast.success("Vocabulary generated Successfully");
          setTotalRegenerationCount((prevCount) => {
            const newCount = prevCount + 1;

            return newCount; // Return the new count
          });

          setShowRegenerateVocabularyModal(false);
          setLoading(false);

          // navigate("/edit-vocab-list");
          break;
        } else {
          setShowModal(false);

          // setShowErosModal(true);
          console.error("Invalid data format", data);
        }
        break;
      } catch (error) {
        setShowModal(false);
        // setShowErosModal(true);
        console.error(
          `API call failed (Attempt ${attempts + 1}/${maxRetries}):`,
          error
        );
        attempts += 1;

        if (attempts >= maxRetries) {
          // setApiError(
          //   "Error in Generating. Go back to Dashboard and please try again later."
          // );
          setShowModal(false);

          // setShowErosModal(true);
          toast.error(
            "Error in Generating. Go back to Dashboard and please try again later."
          );
          setShowModal(false);
        } else {
          console.log("Retrying the API call...");
        }
      }
    }
  };

  const handleTestNameSubmit = (name) => {
    setShowTestNameModal(false);
    submitClicked(name);
  };
  const submitClicked = async (testName) => {
    // const accessToken = localStorage.getItem("accessToken");

    setLoading(true);
    // const Userid = localStorage.getItem("email");

    const practiceInfo = {
      topic: vocabularyDetails.topic,
      source_language: vocabularyDetails.source_language,
      target_language: vocabularyDetails.target_language,
      level: vocabularyDetails.level,
      region: vocabularyDetails.region,
      num_keywords: parseInt(vocabularyDetails.num_keywords, 10),
      num_lists: REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE,
      user_id: currentUser.email,
      practice_name: testName,
    };

    // const keywords = vocabList[displayedList];
    const dataToSubmit = {
      practice_info: practiceInfo,
      keywords: keywords,
    };

    try {
      const result = await updateVocabulary(
        id,
        dataToSubmit,
        currentUser.access
      );
      // console.log("Success:", result);

      localStorage.removeItem("vocabState");
      toast.success(`${testName} Update updated successfully`);
      navigate(`/transcript/${id}`);
    } catch (error) {
      if (error.message.includes("already in use")) {
        // setErrorMessage(
        //   "The practice name you entered is already in use. Please choose a different practice name."
        // );
        toast.error(
          "The practice name you entered is already in use. Please choose a different practice name."
        );
        setShowTestNameModal(true);
      } else {
        console.error("Error:", error);
        toast.error("An unexpected error occurred. Please try again later.");
        // setErrorMessage(
        //   "An unexpected error occurred. Please try again later."
        // );
      }
    } finally {
      setLoading(false);
    }
  };
  const closeModal = () => {
    setShowOpsModal(false);
  };
  return (
    <div className="min-screen edit-list">
      {loading && <Loader />}
      {showModal && (
        <ConfirmDeleteModal
          showModal={showModal}
          onClose={handleCloseModal}
          onDelete={handleConfirmDelete}
          message={"Are you sure you want to delete this Keyword?"}
        />
      )}
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="container">
          <div className="edit-contnet">
            <h1 className="title">Edit Vocabulary List </h1>
            <div className="scrollbar">
              <div className="viewBox">
                <button
                  className="submit"
                  onClick={() => showRegenerateVocabulary()}
                >
                  Full Regenerate List
                </button>
                <button className="lightVocab">
                  <Link
                    className="noDecoration"
                    onClick={handleViewCompleteVocabulary}
                  >
                    View complete vocabulary
                  </Link>
                </button>

                <button
                  className="submit"
                  onClick={() => setShowTestNameModal(true)}
                >
                  Submit
                </button>
              </div>
              {keywords?.length > 0 ? (
                keywords.map((item, index) => (
                  <div
                    className={`card ${
                      index === highlightedCardIndex ? "highlighted" : ""
                    }`}
                    key={index}
                    ref={(el) => (cardRefs.current[index] = el)}
                  >
                    <div className="card-body">
                      <div className="input-box">
                        <label className="form-label">Keyword </label>
                        <input
                          type="text"
                          className="form-control input-center"
                          value={item.source_language}
                          readOnly={index !== focusedKeywordIndex}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "source_language",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="input-box">
                        <label className="form-label">Meaning</label>
                        <textarea
                          rows="3"
                          cols="50"
                          className="form-control"
                          value={item.target_language}
                          readOnly={index !== focusedKeywordIndex}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "target_language",
                              e.target.value
                            )
                          }
                        ></textarea>
                      </div>
                      <div className="btn-list">
                        {index === focusedKeywordIndex ? (
                          <>
                            <button
                              className="btn btn-success"
                              onClick={handleSaveClick}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={handleCancelClick}
                            >
                              Cancel
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="btn btn-success"
                              onClick={() => setFocusedKeywordIndex(index)}
                            >
                              Edit
                            </button>
                            <div className="regenratebutton">
                              {" "}
                              {item.history && item.currentIndex > 0 && (
                                <button
                                  className="btn btn-secondary roundbtn"
                                  onClick={() =>
                                    handleNavigateHistory(index, "previous")
                                  }
                                >
                                  <img src={images.ArrowLeft} alt="ArrowLeft" />
                                  <span className="tooltip">
                                    Previous Vocabulary
                                  </span>
                                </button>
                              )}
                              <button
                                className="btn btn-primary "
                                onClick={() => handleRegenerate(index)}
                              >
                                Regenerate
                              </button>
                              {item.history &&
                                item.currentIndex < item.history.length - 1 && (
                                  <button
                                    className="btn btn-secondary roundbtn"
                                    onClick={() =>
                                      handleNavigateHistory(index, "next")
                                    }
                                  >
                                    <span className="tooltip">
                                      Next Vocabulary
                                    </span>
                                    <img
                                      src={images.ArrowRight}
                                      alt="ArrowRight"
                                    />
                                  </button>
                                )}
                            </div>
                            <button
                              className="btn btn-danger"
                              onClick={() => handleDeleteClick(index)}
                            >
                              Delete
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No vocabulary found.</p>
              )}
            </div>
            <div className="btn-submit-list">
              <button
                className="submit"
                onClick={() => setShowTestNameModal(true)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <Footer />
        {showTestNameModal && (
          <TestNameModal
            show={showTestNameModal}
            name={vocabularyDetails.practice_name}
            onClose={() => setShowTestNameModal(false)}
            onSubmit={handleTestNameSubmit}
            errorMessage={(errorMessage) => toast.error(errorMessage)}
          />
        )}
        {showCompleteVocabularyModal && (
          <VocabListModal
            vocabList={keywords}
            url={`/vocabulary/keywords/${id}`}
            onClose={() => setShowCompleteVocabularyModal(false)}
          />
        )}

        {showRegenerateVocabularyModal && (
          <RegenerateTranscript
            show={showRegenerateVocabularyModal}
            name={""}
            type={"Vocabulary"}
            onClose={() => setShowRegenerateVocabularyModal(false)}
            onSubmit={regenerateVocabulary}
            errorMessage={(errorMessage) => toast.error(errorMessage)}
          />
        )}
        {showOpsModal && (
          <OpsModal
            show={showOpsModal}
            onClose={closeModal}
            model={"Vocabulary"}
            onNextStep={closeModal}
          />
        )}
      </div>
    </div>
  );
};

export default EditVocabularyKeywords;
