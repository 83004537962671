import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import images from "../../images";
import "./Header.css";
import { useLocation, useNavigate } from "react-router";
import { logout } from "../../services/user.service";
import { UserContext } from "../../providers/UserContext";

function HeaderComponent() {
  const [firstName, setFirstName] = useState("");
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const { currentUser, resetCurrentUser } = useContext(UserContext);
  const [usertoken] = useState(currentUser.access || "");
  const [userrefreshToken] = useState(currentUser.access || "");
  // const [userName] = useState(currentUser.first_name || "");
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (!accessToken) {
      localStorage.clear();
      navigate("/login");
      return;
    }
  }, [accessToken, currentUser]);

  const logoClick = () => {
    navigate("/welcome");
  };
  const DashboardClick = () => {
    navigate("/practice-dashboard");
  };
  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    const storedFirstName = currentUser.first_name;
    if (storedFirstName) {
      const name =
        storedFirstName.charAt(0).toUpperCase() +
        storedFirstName.slice(1).toLowerCase();
      setFirstName(name);
    }
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentUser]);
  const isActiveDashboard = location.pathname === "/practice-dashboard";
  const logOutClick = async () => {
    try {
      if (!usertoken) {
        resetCurrentUser();
        localStorage.clear();
        navigate("/login");
        // throw new Error("No refresh token found.");
        return;
      }

      const data = await logout(usertoken, userrefreshToken);

      if (data.status) {
        throw new Error(`HTTP error! Status: ${data.message}`);
      }
      resetCurrentUser();
      // Clear authentication data from local storage
      localStorage.clear();
      // Redirect to login or home page
      navigate("/login");
    } catch (error) {
      resetCurrentUser();
      // Clear authentication data from local storage
      localStorage.clear();
      navigate("/login");
      console.error("Logout error:", error);
    }
  };
  const ProfileCliked = () => {
    navigate("/update-profile");
  };

  return (
    <Navbar
      bg="transparent"
      expand="lg"
      className={`custom-navbar ${isSticky ? "sticky" : ""}`}
    >
      <Navbar.Brand href="#">
        <img
          src={images.logo}
          alt="logo"
          className="logo-img"
          onClick={logoClick}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarSupportedContent" />
      <Navbar.Collapse id="navbarSupportedContent">
        <Nav className="ml-auto nav-links p-2">
          <Nav.Link
            className={`mb-1 ${isActiveDashboard ? "active" : ""}`}
            onClick={DashboardClick}
          >
            Dashboard
          </Nav.Link>
          {/* <Nav.Link className="mb-1" href="#">
            <img src={images.user} alt="user" className="user-icon" />
          </Nav.Link> */}
          <NavDropdown title={firstName} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={ProfileCliked}>
              Profile{" "}
            </NavDropdown.Item>
            <NavDropdown.Item onClick={logOutClick}>Log Out</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default HeaderComponent;
