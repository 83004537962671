import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import images from "../../images";
import Button from "../../components/Button/SignupButton";
import "./Login.css";
import { loginUser } from "../../services/user.service";
import { toast } from "react-toastify";
import { UserContext } from "../../providers/UserContext";

function LoginScreen() {
  const navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [serverError, setServerError] = useState("");
  const { currentUser, updateCurrentUser } = useContext(UserContext);
  const [usertoken] = useState(currentUser.access || "");
  useEffect(() => {
    // console.log(currentUser, "currentUser");
    // const accessToken = localStorage.getItem("accessToken");
    if (currentUser.access) {
      navigate("/practice-dashboard");
      return;
    }
  }, [currentUser]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    let valid = true;

    const userData = { email: Email, password: Password };
    setEmailError("");
    setPasswordError("");
    setServerError("");

    if (!Email) {
      setEmailError("Email is required.");
      toast.error("Email is required.");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(Email)) {
      setEmailError("Invalid email address.");
      toast.error("Invalid email address.");
      valid = false;
    }

    if (!Password) {
      setPasswordError("Password is required.");
      toast.error("Password is required.");
      valid = false;
    }

    if (!valid) return;

    try {
      const data = await loginUser(userData);

      if (data && data.access) {
        console.log("User data received:", data);
        updateCurrentUser(data);
        toast.success(`welcome ${data.first_name} !!`);
        // After successful login
        const redirectUrl = localStorage.getItem("redirectAfterLogin");
        if (redirectUrl) {
          navigate(redirectUrl);
          localStorage.removeItem("redirectAfterLogin");
        } else {
          // Default redirection if no stored URL
          navigate("/welcome");
        }
      } else {
        toast.error("No access token found in response.");
      }
    } catch (error) {
      console.error("error:", error);
      setServerError(error.message);
      toast.error(error.message);
    }
  };

  const handleSignUp = () => {
    navigate("/signup");
  };
  const ForgotClick = () => {
    navigate("/forgot-password");
  };
  return (
    <div className="min-screen screen1">
      <div className="main-content">
        <div className="login-form">
          <form onSubmit={handleSignIn}>
            <img src={images.logo} alt="logo" className="logo" />
            <h2>Log in to your account</h2>

            <div className="inputForm">
              <img src={images.mailIcon} alt="mail" className="inputicon" />
              <input
                type="text"
                className="custom-input"
                placeholder="Email"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {emailError && (
              <div className="login-error-message">{emailError}</div>
            )}
            <div className="inputForm mt-3">
              <img src={images.lockIcon} alt="lock" className="inputicon" />
              <input
                type={isPasswordShow ? "text" : "password"}
                className="custom-input"
                placeholder="Password"
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <i
                className={`bi ${isPasswordShow ? "bi-eye" : "bi-eye-slash"}`}
                alt="lock"
                style={{ color: "white", fontSize: "30px", cursor: "pointer" }}
                onClick={() => setIsPasswordShow(!isPasswordShow)}
              ></i>
            </div>
            {passwordError && (
              <div className="login-error-message">{passwordError}</div>
            )}
            {serverError && (
              <div className="server-error-message">{serverError}</div>
            )}
            <div className="forgot-div">
              <div className="d-flex align-items-center input-box">
                <input type="checkbox" name="" />
                <span> Remember me</span>
              </div>
              <div className="forgot" onClick={ForgotClick}>
                Forgot Password ?
              </div>
            </div>

            <Button type="submit" className="success">
              Sign in
            </Button>
            <Button type="button" className="light" onClick={handleSignUp}>
              Sign up
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginScreen;
