import React from "react";

const Footer = () => {
  return (
    <div className="Copyright">
      <p>Copyright © Interpretrain, All rights reserved 2024.</p>
    </div>
  );
};

export default Footer;
