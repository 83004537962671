import React, { useContext, useEffect, useState } from "react";
import "../../App.css";
// import logo from "../../assets/Images/logo.jpg";
import rightImg from "../../assets/Images/got_it_right.png";
import wrongImg from "../../assets/Images/got_it_wrong.png";
import Tabs from "../../components/Tabs/Tabs"; // Adjust the import path as needed
import TabContent from "../../components/Tabs/TabContent"; // Adjust the import path as needed
import Pagination from "../../components/Pagination/Pagination"; // Adjust the import path as needed
import "./GameQuestionAndFlashCard.css";
import Card from "./Card/Card"; // Make sure the path is correct
import Quiz from "./Quiz/Quiz"; // Make sure the path is correct
import HeaderWhiteComponent from "../../components/HeaderWhite/HeaderWhite";

import { useNavigate, useParams } from "react-router-dom";
import Accordion from "../../components/Accordion/Accordion";
import { Button } from "react-bootstrap";
import {
  getKeywordsByIdentifier,
  getVocabById,
} from "../../services/user.service";
import { UserContext } from "../../providers/UserContext";
import images from "../../images";
import { toast } from "react-toastify";
import QuizModal from "../../components/Modal/QuizConfirmModal/QuizConfirm";

const GameQuestionAndFlashCard = () => {
  const { id } = useParams();
  console.log("id:", id);
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const [usertoken] = useState(currentUser.access || "");
  const [activeTab, setActiveTab] = useState("flashcards");
  const [currentPage, setCurrentPage] = useState(1);
  const [quizData, setQuizData] = useState([]);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);
  const [showResult, setShowResult] = useState(false);
  const [isShowFront, setIsShowFront] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
  const [correctAnswers, setCorrectAnswers] = useState(0); // Track correct answers
  const [totalAnswers, setTotalAnswers] = useState(0); // Track total answers

  const cardsPerPage = 1; // Adjust based on how many cards per page

  const handleTabClick = (tabId) => {
    setCurrentPage(1);
    if (tabId === "quiz") {
      setCurrentQuestionIndex(0);
    } else {
      setCurrentQuestionIndex(1);
    }

    setActiveTab(tabId);
    setSelectedAnswer(null); // Reset selected answer for the next question
  };

  useEffect(() => {
    if (currentUser && currentUser.access) {
      getVocab();
    }
  }, [id, currentUser]);

  // Utility function to shuffle answers
  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  const getVocab = async () => {
    const payload = {
      identifier: id,
    };
    try {
      const response = await getVocabById(payload, currentUser.access);

      if (response) {
        // Function to generate quiz data from JSON
        let quizArray = response.keyword.keywords_list.map((item, index) => {
          // Get the correct answer from the definition
          const correctAnswer = item.target_language;

          // Get random answers, including the correct one
          const allDefinitions = response.keyword.keywords_list.map(
            (d) => d.target_language
          );
          let answers = shuffleArray([
            correctAnswer,
            ...shuffleArray(
              allDefinitions.filter((ans) => ans !== correctAnswer)
            ).slice(0, 3),
          ]);

          return {
            id: index + 1,
            question: item.source_language,
            answers: answers,
            correct: correctAnswer,
          };
        });

        console.log("quizArray", quizArray);
        setQuizData(quizArray);
      }
    } catch {
      // navigate("/login");
    }
  };

  const tabs = [
    { id: "flashcards", label: "Flash Cards" },
    { id: "memorize", label: "Memorize" },
    { id: "quiz", label: "Quiz" },
    { id: "show-all", label: "Show All" },
  ];

  // const quizData = [
  //     {
  //       id: 1,
  //       question: "What is the capital of France?",
  //       answers: ["Berlin", "Madrid", "Paris", "Rome"],
  //       correct: "Paris",
  //     },
  //     {
  //       id: 2,
  //       question: "Which planet is known as the Red Planet?",
  //       answers: ["Earth", "Mars", "Jupiter", "Saturn"],
  //       correct: "Mars",
  //     },
  //     {
  //       id: 3,
  //       question: "Who wrote 'To Kill a Mockingbird'?",
  //       answers: ["Harper Lee", "J.K. Rowling", "Ernest Hemingway", "F. Scott Fitzgerald"],
  //       correct: "Harper Lee",
  //     },
  //     {
  //       id: 4,
  //       question: "What is the largest ocean on Earth?",
  //       answers: ["Atlantic Ocean", "Indian Ocean", "Arctic Ocean", "Pacific Ocean"],
  //       correct: "Pacific Ocean",
  //     },
  //     {
  //       id: 5,
  //       question: "Who painted the Mona Lisa?",
  //       answers: ["Vincent van Gogh", "Pablo Picasso", "Leonardo da Vinci", "Claude Monet"],
  //       correct: "Leonardo da Vinci",
  //     },
  //     {
  //       id: 6,
  //       question: "Which element has the chemical symbol 'O'?",
  //       answers: ["Oxygen", "Gold", "Iron", "Helium"],
  //       correct: "Oxygen",
  //     },
  //     {
  //       id: 7,
  //       question: "In which year did the Titanic sink?",
  //       answers: ["1912", "1905", "1918", "1925"],
  //       correct: "1912",
  //     },
  //     {
  //       id: 8,
  //       question: "Which country gifted the Statue of Liberty to the USA?",
  //       answers: ["France", "Spain", "Italy", "Germany"],
  //       correct: "France",
  //     },
  //     {
  //       id: 9,
  //       question: "What is the hardest natural substance on Earth?",
  //       answers: ["Gold", "Iron", "Diamond", "Silver"],
  //       correct: "Diamond",
  //     },
  //     {
  //       id: 10,
  //       question: "Who developed the theory of relativity?",
  //       answers: ["Isaac Newton", "Nikola Tesla", "Albert Einstein", "Galileo Galilei"],
  //       correct: "Albert Einstein",
  //     }
  //   ];

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = quizData.slice(indexOfFirstCard, indexOfLastCard);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setIsShowFront(!isShowFront);
    if (pageNumber >= 0 && pageNumber < quizData.length) {
      setCurrentQuestionIndex(pageNumber);
      setSelectedAnswer(null); // Reset selected answer for new question
      setIsAnswerCorrect(null); // Reset correctness for new question
    }
  };

  const handleAnswerSelect = (selectedAnswer) => {
    setSelectedAnswer(selectedAnswer);

    // Increment total answers
    setTotalAnswers((prev) => prev + 1);

    // Check if the selected answer is correct
    const isCorrect = selectedAnswer === quizData[currentQuestionIndex].correct;

    if (isCorrect) {
      // Increment correct answers count
      setCorrectAnswers((prev) => prev + 1);
    }
  };

  const nextQuestion = () => {
    if (selectedAnswer === null) {
      // Alert user if no answer is selected
      toast.error(
        "Please select an answer before proceeding to the next question."
      );
      return;
    }
    setTimeout(() => {
      const nextQuestionIndex = currentQuestionIndex + 1;

      if (nextQuestionIndex < quizData.length) {
        // Move to the next question
        handlePageChange(nextQuestionIndex);
        setSelectedAnswer(null); // Reset selected answer for the next question
      } else {
        // Show results if quiz is complete
        setShowResult(true);
      }
    }, 200);
  };

  const handleOnClickWrong = (currentPage) => {
    setTotalAnswers((prev) => prev + 1);
    const total = Math.ceil(quizData.length / cardsPerPage);
    if (total !== currentPage) {
      handlePageChange(currentPage + 1);
    }
  };

  const handleOnClickRight = (currentPage) => {
    setTotalAnswers((prev) => prev + 1);
    setCorrectAnswers((prev) => prev + 1);
    const total = Math.ceil(quizData.length / cardsPerPage);
    if (total !== currentPage) {
      handlePageChange(currentPage + 1);
    }
  };
  const BacktoDashboard = () => {
    navigate(-1);
    // navigate("/practice-dashboard");
  };
  useEffect(() => {
    if (quizData.length > 0 && totalAnswers === quizData.length) {
      setShowResult(true);
    }
  }, [totalAnswers, quizData]);

  const calculatePercentage = () => {
    if (totalAnswers === 0) return 0;
    return ((correctAnswers / totalAnswers) * 100).toFixed(2);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Function to hide the modal
  const hideModal = () => {
    setIsModalVisible(false);
  };

  // Function to handle modal confirm action
  const handleConfirm = () => {
    // Hide the modal after confirmation
    setShowResult(false);
    setCorrectAnswers(0);
    setTotalAnswers(0);
    setCurrentPage(1);
    setCurrentQuestionIndex(0);
    setIsModalVisible(false);
  };
  return (
    <div className="min-screen game-question-card">
      <HeaderWhiteComponent className="headerr" />

      {showResult ? (
        <div className="main-content">
          <div>
            <button className="back-to-dash" onClick={BacktoDashboard}>
              <img
                className="back-dash-right"
                src={images.ArrowLeftwhite}
                alt=""
              />
              Back To Dashboard
            </button>
          </div>
          <div className="score-card">
            <h2 className="title">Your Score</h2>
            <h2 className="Score">{calculatePercentage()}%</h2>
            <p className="des">
              {correctAnswers} OUT OF {totalAnswers} ARE CORRECT
            </p>

            {/* <a
              onClick={() => {
                const confirmRestart = window.confirm(
                  "The quiz will restart from the beginning, and your results will not be saved."
                );
                if (confirmRestart) {
                  setShowResult(false);
                  setCorrectAnswers(0);
                  setTotalAnswers(0);
                  setCurrentPage(1);
                  setCurrentQuestionIndex(0);
                }
              }}
            >
              Back To Quiz
            </a> */}
            <a onClick={showModal}>Back To Quiz</a>
          </div>
        </div>
      ) : (
        <div className="mainContentBoxFlashCard">
          <div className="main-content flash-card">
            {/* <img src={logo} alt="logo" className="logo" /> */}
            <button
              className="flash-card-back-button"
              title="Back to Dashboard"
              onClick={BacktoDashboard}
            >
              <img
                className="back-dash-right"
                src={images.ArrowLeftwhite}
                alt=""
              />
            </button>
            <Tabs
              activeTab={activeTab}
              onTabClick={handleTabClick}
              tabs={tabs}
            />

            <div className="tab-content">
              <TabContent activeTab={activeTab} tabId="flashcards">
                <div>
                  <Pagination
                    total={Math.ceil(quizData.length / cardsPerPage)}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />

                  {currentCards &&
                    currentCards.length &&
                    currentCards.map((quiz, index) => (
                      <Card
                        key={index}
                        frontContent={quiz.question}
                        backContent={quiz.correct}
                        isFront={isShowFront} // Always show the front first when navigating
                      />
                    ))}
                </div>
              </TabContent>

              <TabContent activeTab={activeTab} tabId="memorize">
                {currentCards &&
                  currentCards.length &&
                  currentCards.map((quiz, index) => (
                    <Card
                      key={index}
                      frontContent={quiz.question}
                      backContent={quiz.correct}
                      isFront={isShowFront} // Always show the front first when navigating
                    />
                  ))}
                <div className="buttom-btn">
                  <Button onClick={() => handleOnClickWrong(currentPage)}>
                    <img src={wrongImg} alt="Wrong Img" />
                    Got it Wrong
                  </Button>
                  <Button onClick={() => handleOnClickRight(currentPage)}>
                    <img src={rightImg} alt="Right Img" />
                    Got it Right
                  </Button>
                </div>
              </TabContent>

              <TabContent activeTab={activeTab} tabId="quiz">
                {quizData && quizData.length ? (
                  <>
                    <Quiz
                      question={quizData[currentQuestionIndex].question}
                      answers={quizData[currentQuestionIndex].answers}
                      correct={quizData[currentQuestionIndex].correct}
                      onAnswerSelect={handleAnswerSelect}
                      selectedAnswer={selectedAnswer}
                    />
                    {/* <Pagination
                      total={quizData.length}
                      currentPage={currentQuestionIndex}
                      onPageChange={handlePageChange}
                    /> */}
                    <div className="questionAnsbutton">
                      <button onClick={nextQuestion} className="next-button">
                        Next Question ❯
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </TabContent>

              <TabContent activeTab={activeTab} tabId="show-all">
                {quizData && quizData.length ? (
                  <Accordion questions={quizData} />
                ) : (
                  ""
                )}
              </TabContent>
            </div>
          </div>
        </div>
      )}
      <QuizModal
        isVisible={isModalVisible}
        onConfirm={handleConfirm}
        onCancel={hideModal}
        onClose={hideModal}
      >
        <p>
          The quiz will restart from the beginning, and your results will not be
          saved.
        </p>
      </QuizModal>
    </div>
  );
};

export default GameQuestionAndFlashCard;
