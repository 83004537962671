import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import HeaderComponent from "components/Header/Header";
import Button from "components/Button/SignupButton";
import { FormContext } from "../../../providers/FormContext";
import languagesData from "../../../language.json";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer/Footer";

const SelectLanguage = () => {
  const { formData, updateFormData } = useContext(FormContext);
  const [error, setError] = useState("");

  const handleSourceChange = (selectedOption) => {
    updateFormData("sourceLanguage", selectedOption.value);
    if (selectedOption.value && formData.targetLanguage) {
      setError("");
    }
  };

  const handleTargetChange = (selectedOption) => {
    updateFormData("targetLanguage", selectedOption.value);
    if (formData.sourceLanguage && selectedOption.value) {
      setError("");
    }
  };

  const handleNextClick = (e) => {
    if (!formData.sourceLanguage || !formData.targetLanguage) {
      e.preventDefault();
      setError("Please select both source and target languages.");
      toast.error("Please select both source and target languages.");
    }
  };
  // console.log(languagesData., "language");

  const languageOptions = languagesData.map((language) => ({
    value: language.fullname,
    label: language.fullname,
  }));
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgba(18, 38, 63, 0.9)", // Closer to dark blue in your image
      borderRadius: "10px", // Slightly more rounded corners
      borderColor: "rgba(255, 255, 255, 0.15)", // Soft white border
      color: "#fff",
      padding: "4px 8px",
      boxShadow: "0 3px 15px rgba(0, 0, 0, 0.2)", // Softer shadow for a subtle lift
      minHeight: "48px", // Adjust height as needed
      fontWeight: 400, // Regular font weight
      fontSize: "16px", // Standard text size
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "rgba(18, 38, 63, 0.9)", // Matching dark blue with high transparency
      borderRadius: "10px",
      boxShadow: "0 3px 15px rgba(0, 0, 0, 0.25)", // Consistent shadow with control
      borderColor: "rgba(255, 255, 255, 0.15)",
      overflow: "hidden", // Ensures the rounded corners contain all child elements
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgb(120, 124, 137)"
        : "rgb(70, 75, 92)",
      color: "#FFF",
      padding: "15px 20px",
      margin: "8px",
      maxWidth: "540px",
      borderRadius: "15px",
      // Generous padding for a spacious feel
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#FFF",
    }),
    input: (provided) => ({
      ...provided,
      color: "#FFF", // White text for input
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#CCC", // Placeholder text color for better contrast
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white", // Consistent white arrow
      "&:hover": {
        color: "rgba(255, 255, 255, 0.75)", // Slightly dim on hover for a dynamic effect
      },
    }),
    indicatorSeparator: () => ({
      display: "none", // Cleaner look without the separator
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0px", // Remove padding to match your aesthetic requirements
    }),
  };

  // const customStyles = {
  //   control: (provided) => ({
  //     ...provided,
  //     backgroundColor: "rgba(255, 255, 255, 0.08)",
  //     borderRadius: "8px",
  //     color: "#fff",
  //     padding: "8px",
  //     boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)", // Add slight shadow
  //     border: "2px solid rgba(255, 255, 255, 0.3)", // Matches the border from the image
  //     minHeight: "50px",
  //     // "&:hover": {
  //     //   borderColor: "#FFFFFF2E", // Hover effect for interactivity
  //     // },
  //   }),
  //   menu: (provided) => ({
  //     ...provided,
  //     backgroundColor: "#00081F",
  //     borderRadius: "8px",
  //     color: "white",
  //     padding: "10px",
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isFocused ? "#FFFFFF47" : "#00081F", // Focused option color
  //     color: state.isFocused ? "white" : "white", // Text color when selected
  //     padding: "10px",
  //     minHeight: "50px",
  //     borderRadius: "8px",
  //   }),
  //   singleValue: (provided) => ({
  //     ...provided,
  //     color: "white", // White text for selected option
  //   }),
  //   input: (provided) => ({
  //     ...provided,
  //     color: "white", // White input text
  //   }),
  //   placeholder: (provided) => ({
  //     ...provided,
  //     color: "#a7a7a7", // Lighter gray for placeholder text
  //   }),
  //   indicatorSeparator: () => ({
  //     display: "none", // Remove the separator for a cleaner look
  //   }),
  //   dropdownIndicator: (provided) => ({
  //     ...provided,
  //     color: "white", // White dropdown arrow
  //     "&:hover": {
  //       color: "white", // Orange arrow on hover
  //     },
  //   }),
  // };
  return (
    <div className="min-screen screen4">
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="main-content">
          <h1 className="title">
            Vocabulary Flashcards for Interpreter Practice
          </h1>
          <p className="des">
            Please choose the source and target languages to generate the
            vocabulary list. This will enable us to provide you with the most
            accurate and relevant vocabulary for your language learning needs.
          </p>
          <form className="form_">
            <label className="form-label">
              In what language combination?{" "}
              <span className="text-danger">*</span>
            </label>
            <div className="row">
              <div className="col-md-6 select-language mb-3">
                <Select
                  options={languageOptions}
                  onChange={handleSourceChange}
                  placeholder="Select source language"
                  styles={customStyles}
                  defaultValue={
                    formData.sourceLanguage
                      ? {
                          value: formData.sourceLanguage,
                          label: formData.sourceLanguage,
                        }
                      : null
                  }
                />
              </div>
              <div className="col-md-6">
                <Select
                  options={languageOptions}
                  onChange={handleTargetChange}
                  placeholder="Select source language"
                  styles={customStyles}
                  defaultValue={
                    formData.targetLanguage
                      ? {
                          value: formData.targetLanguage,
                          label: formData.targetLanguage,
                        }
                      : null
                  }
                />
              </div>
            </div>
            {error && <p className="text-danger m-2">{error}</p>}
          </form>
          <div className="btn-list">
            <Link to="/vocabulary-screen" className="noDecoration">
              <Button className="small">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                </svg>
                Back
              </Button>
            </Link>
            <Link
              to="/vocabulary-topic"
              className="noDecoration"
              onClick={handleNextClick}
            >
              <Button className="small">
                next
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                </svg>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SelectLanguage;
