// src/services/authService.js

import { REACT_APP_FORGOT_PASSWORD, REACT_APP_GET_KEYWORDS_API, REACT_APP_GET_VOCAB_API, REACT_APP_LOGIN_API, REACT_APP_LOGOUT, REACT_APP_PROFILE_UPDATE, REACT_APP_REGISTER_API, REACT_APP_RESET_PASSWORD, REACT_APP_USER_DETAILS } from "../constants";

export const loginUser = async (userData) => {
    try {
        const response = await fetch(REACT_APP_LOGIN_API, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userData),
        });

        if (!response.ok) {
            throw new Error("Invalid email or password.");
        }

        const data = await response.json();
        // const expiryTime = Date.now() + 15 * 60 * 1000;
        localStorage.setItem("accessToken", data.access);
        // localStorage.setItem("email", data.email);
        // localStorage.setItem("first_name", data.first_name);
        // localStorage.setItem("expiryTime", expiryTime.toString());
        if (data.refresh) {
            localStorage.setItem("refreshToken", data.refresh);
        }
        return data;
    } catch (error) {
        console.error("error:", error);
        throw error;
    }
};


export const registerUser = async (userData) => {
    try {
        const response = await fetch(REACT_APP_REGISTER_API, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userData),
        });

        if (response.ok) {
            return { success: true, message: "Account Successfully Created" };
        } else {
            const result = await response.json();
            return { success: false, message: result.msg || "Registration failed" };
        }
    } catch (error) {
        console.error("error:", error);
        return { success: false, message: "Something went wrong. Please try again later." };
    }
};

export const getVocabById = async (requestData, usertoken) => {

    try {
        const response = await fetch(REACT_APP_GET_VOCAB_API, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${usertoken}`,
            },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            // Create a more detailed error message
            const errorMessage = await response.text();
            throw new Error(`Data not found. Status: ${response.status}. Message: ${errorMessage}`);
        }

        // Parse the response as JSON
        const resultData = await response.json();

        // Check if the resultData is empty or not
        if (!resultData) {
            throw new Error("No data found in response.");
        }

        return resultData;
    } catch (error) {
        console.error("Error fetching vocab data:", error.message);
        return error;
    }
};

export const getKeywordsByIdentifier = async (requestData) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
        const response = await fetch(`${REACT_APP_GET_KEYWORDS_API}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            // Create a more detailed error message
            const errorMessage = await response.text();
            throw new Error(`Data not found. Status: ${response.status}. Message: ${errorMessage}`);
        }

        // Parse the response as JSON
        const resultData = await response.json();

        // Check if the resultData is empty or not
        if (!resultData) {
            throw new Error("No data found in response.");
        }

        return resultData;
    } catch (error) {
        console.error("Error fetching vocab data:", error.message);
        throw error;
    }
};


export const getUserDetails = async (accessToken) => {
    try {
        // const response = await fetch(`${REACT_APP_USER_DETAILS}?access_token=${accessToken}`);
        const response = await fetch(`${REACT_APP_USER_DETAILS}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ access_token: accessToken }),
        });
        if (!response.ok) {
            throw new Error('Error fetching user details');
        }
        const data = await response.json();
        return {
            ...data,
            access: accessToken
        };
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
};


export const logout = async (accessToken, refreshToken) => {
    try {
        const response = await fetch(
            REACT_APP_LOGOUT,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ refresh_token: refreshToken }),
            }
        );

        if (response.ok) {
            return { success: true, message: "Account logout Successfully" };
        } else {
            const result = await response.json();
            return { success: false, message: result.msg || "logout failed" };
        }
    } catch (error) {
        console.error("error:", error);
        return { success: false, message: "Something went wrong. Please try again later." };
    }
};



export const ForgotPassword = async (userData) => {
    try {
        const response = await fetch(REACT_APP_FORGOT_PASSWORD, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userData),
        });

        if (!response.ok) {
            throw new Error("Invalid email ");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("error:", error);
        throw error;
    }
};
export const ResetPasswordService = async (userData) => {
    try {
        const response = await fetch(
            `${REACT_APP_RESET_PASSWORD}${userData.uidb64}/${userData.token}/`,
            {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userData),
        });

        if (!response.ok) {
            throw new Error("Invalid email ");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("error:", error);
        throw error;
    }
};
export const updateProfile = async (accessToken, profileData) => {
  try {
    const response = await fetch(REACT_APP_PROFILE_UPDATE, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(profileData),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message || "Failed to update profile");
  }
};
