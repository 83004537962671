import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setVocabList } from "../../../redux/vocabSlice";
import HeaderComponent from "components/Header/Header";
import Button from "components/Button/SignupButton";
import { FormContext } from "../../../providers/FormContext";
import ModalComponent from "../../../components/Modal/VocabModal/VocabModal";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer/Footer";
import { REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE } from "../../../constants";
import { generateVocabulary } from "../../../services/vocab.service";
import ErrorModalComponent from "../../../components/Modal/TryAgainModal/TryAgainModal";
import { UserContext } from "../../../providers/UserContext";

const GeographicalArea = () => {
  const { formData, updateFormData } = useContext(FormContext);
  const { currentUser } = useContext(UserContext);
  const [region, setRegion] = useState(formData.region || "");

  const [error, setError] = useState("");
  const [showErorModal, setShowErosModal] = useState(false);

  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const handleRegionChange = (e) => {
    setRegion(e.target.value);
    updateFormData("region", e.target.value);
    if (e.target.value) {
      setError("");
    }
  };
  const closeModal = () => {
    // handleNextClick();
    setShowErosModal(false);
  };
  const onClose = () => {
    setShowErosModal(false);
  };
  useEffect(() => {
    console.log(currentUser, "currentUser");
  }, [currentUser]);
  const handleNextClick = async () => {
    setShowErosModal(false);
    // const token = localStorage.getItem("accessToken");
    if (!region) {
      setError("Please specify the geographical region.");
      toast.error("Please specify the geographical region.");
      return;
    }

    // console.log("usertoken", currentUser.access)
    setShowModal(true);
    setApiError("");

    const maxRetries = 2;
    let attempts = 0;
    let success = false;

    while (attempts < maxRetries && !success) {
      try {
        const data = await generateVocabulary(
          currentUser.access,
          formData,
          region,
          "",
          REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE
        );

        if (
          data &&
          data.keywords &&
          data.keywords.keywords &&
          Array.isArray(data.keywords.keywords.lists)
        ) {
          console.log(
            "data.keywords.keywords.lists",
            data.keywords.keywords.lists[0].length
          );
          dispatch(setVocabList(data.keywords.keywords.lists));
          success = true;
          toast.success("Vocabulary generated Successfully");
          navigate("/edit-vocab-list");
          break;
        } else {
          setShowModal(false);

          setShowErosModal(true);
          console.error("Invalid data format", data);
        }
        break;
      } catch (error) {
        setShowModal(false);
        setShowErosModal(true);
        console.error(
          `API call failed (Attempt ${attempts + 1}/${maxRetries}):`,
          error
        );
        attempts += 1;

        if (attempts >= maxRetries) {
          setApiError(
            "Error in Generating. Go back to Dashboard and please try again later."
          );
          setShowModal(false);

          setShowErosModal(true);
          toast.error(
            "Error in Generating. Go back to Dashboard and please try again later."
          );
          setShowModal(false);
        } else {
          console.log("Retrying the API call...");
        }
      }
    }
  };

  return (
    <div className="min-screen screen7">
      {showModal && (
        <ModalComponent modalText="Generating Vocabulary. Please wait..." />
      )}
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="main-content">
          <h1 className="title">
            Vocabulary Flashcards for Interpreter Practice
          </h1>
          <p className="des">
            Please specify the geographical region where this speech is most
            commonly given. This information will help us tailor the transcript
            to better reflect local dialects, terminologies, and cultural
            context.
          </p>
          <form className="form_">
            <label className="form-label">
              What area of the world would this conversation or speech most
              commonly be given? <span className="text-danger">*</span>
            </label>
            <textarea
              rows="3"
              cols="50"
              className="form-control"
              value={region}
              onChange={handleRegionChange}
              placeholder="Please enter the geographical region where this speech is most commonly given."
            ></textarea>
            {error && <p className="text-danger">{error}</p>}
            {apiError && <p className="text-danger">{apiError}</p>}
          </form>
          <div className="btn-list">
            <Link to="/vocabulary-language-level" className="noDecoration">
              <Button className="small">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                </svg>
                Back
              </Button>
            </Link>
            <Link className="noDecoration">
              <Button className="small" onClick={handleNextClick}>
                next
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                </svg>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
      {showErorModal && (
        <ErrorModalComponent
          modalText="Something went wrong! Please try again."
          onClose={closeModal}
          onClickTryAgain={handleNextClick}
        />
      )}
    </div>
  );
};

export default GeographicalArea;
